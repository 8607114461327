import React, { useState } from "react"
import PageLayoutAuth from "./PageLayoutAuth"
import InputDefault from "components/Input/InputDefault"
import FormInputCard from "./molecules/FormInputCard"
import Header from "./atoms/Header"
import LabelChangeRouter from "./molecules/LabelChangeRouter"
import RememberMeCard from "./molecules/RememberMeCard"
import { PATH } from "constants/path"
import { LoginRequest, defaultLoginRequest } from "./types"
import { STATUS_INPUT } from "components/Input/types"
import {
  isValidEmail,
  changeLabelStatusEmail,
  umamiTracking,
} from "helpers/utils"
import { cloneDeep } from "lodash"
import { useArray, useBoolean } from "helpers/hooks"
import { loginMiddleware } from "./services/api"
import { pushTo } from "helpers/history"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import Button from "components/Button/Button"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { ACTION_RECAPTCHA } from "constants/actionReCaptcha"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { useLocation } from "react-router-dom"
import { EVENT } from "constants/events"
import { TwoFactorForm } from "./molecules/TwoFactorForm"

interface LoginFormProps {
  onLogin: (data: LoginRequest) => void
}

enum LoginFormType {
  Login,
  TwoFactor,
}
const LoginForm = (props: LoginFormProps) => {
  const { onLogin } = props
  const [request, setRequest] = useState<LoginRequest>(defaultLoginRequest)
  const [statusInputEmail, setStatusInputEmail] = useState({
    status: STATUS_INPUT.DEFAULT,
    label: "",
  })
  const checkbox = useBoolean()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmit = async () => {
    if (!executeRecaptcha) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.RECAPTCHA_AVAILABLE}
          type="error"
        />
      )
      return
    }
    const token = await executeRecaptcha(ACTION_RECAPTCHA.LOGIN)
    onLogin({
      ...request,
      captcha: token,
    })
    umamiTracking(EVENT.LOGIN)
  }

  const handleChangeInput = (key: "email" | "password") => (event: any) => {
    setRequest({
      ...request,
      [key]: event.target.value,
    })
    if (key === "email") {
      setStatusInputEmail(changeLabelStatusEmail(event.target.value))
    }
  }
  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButton()) {
        return
      }
      onSubmit()
    }
  }

  const handleDisableButton = () => {
    const newRequest = cloneDeep(request)
    let isDisable = false
    Object.values(newRequest).forEach((el, index) => {
      if (!el) {
        isDisable = true
      } else {
        switch (Object.keys(newRequest)[index]) {
          case "email":
            if (!isValidEmail(el)) {
              isDisable = true
            }
            break
          case "password":
            isDisable = el.length < 1
            break
        }
      }
    })

    return isDisable
  }

  return (
    <div>
      <Header title="Log In to Tracelium" />
      <LabelChangeRouter
        title="Don’t have an account?"
        label="Sign Up"
        path={PATH.signUp}
      />
      <div className="mt-6">
        <FormInputCard title="Email">
          <InputDefault
            type="email"
            value={request.email}
            onChange={handleChangeInput("email")}
            status={statusInputEmail.status}
            labelStatus={statusInputEmail.label}
            onKeyPress={onKeyPress}
          />
        </FormInputCard>
        <FormInputCard title="Password">
          <InputDefault
            type="password"
            isPassword
            value={request.password}
            onChange={handleChangeInput("password")}
            onKeyPress={onKeyPress}
          />
        </FormInputCard>
      </div>
      <div className="mt-3 mb-6 flex items-center justify-between">
        <RememberMeCard
          title="Remember me"
          checked={checkbox.value}
          setChecked={checkbox.setValue}
        />
        <p
          className="font-normal cursor-pointer custom-text-black"
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#7A7A7A",
          }}
          onClick={() => pushTo(PATH.forget)}
        >
          Forgot Password?
        </p>
      </div>
      <Button
        title="Log in"
        onClick={onSubmit}
        isDisabledBtn
        widthBtn="100%"
        disabled={handleDisableButton()}
      />
    </div>
  )
}

const Login = () => {
  const [formType, setFormType] = useState<LoginFormType>(LoginFormType.Login)
  const [oneTimeToken, setOneTimeToken] = useState<string>("")
  const location = useLocation<any>()
  const mfaMethods = useArray([])
  const [labelMfa, setLabelMfa] = useState<{
    maskEmail: string
    maskPhone: string
  }>({
    maskEmail: "",
    maskPhone: "",
  })
  const onLogin = async (loginData: LoginRequest) => {
    //re
    loginMiddleware(loginData)
      .then(async (response) => {
        if (!response.type) {
          localStorage.setItem("access_token", response.token)
          if (location.state?.redirectUrl) {
            pushTo(
              location.state.redirectUrl,
              undefined,
              undefined,
              location.state.search
            )
          } else {
            pushTo(PATH.projects)
          }
        } else {
          setOneTimeToken(response.token)
          setFormType(LoginFormType.TwoFactor)
          mfaMethods.setValue(response.mfaMethods)
          setLabelMfa({
            maskEmail: response.maskEmail,
            maskPhone: response.maskPhone,
          })
        }
      })
      .catch((error) => {
        const message =
          error.response?.data?.message ??
          "Please enter a correct email and password. Note that both fields may be case-sensitive!"
        toast(<LabelNotificationPage messenger={message} type="error" />)
      })
  }
  return (
    <PageLayoutAuth showPrivacy>
      <div className="h-full w-full flex flex-col p-6 relative">
        {formType === LoginFormType.Login ? (
          <LoginForm onLogin={onLogin} />
        ) : (
          <TwoFactorForm
            oneTimeToken={oneTimeToken}
            setOneTimeToken={setOneTimeToken}
            mfaMethods={mfaMethods.value}
            labelMfa={labelMfa}
          />
        )}
      </div>
    </PageLayoutAuth>
  )
}
export default Login
