import { PATH } from "constants/path"
import { pushTo } from "helpers/history"
import { useAppSelector } from "hooks/useApp"
import {
  getUnreadBuildMiddleware,
  getUnreadConversationMiddleware,
  getUserMiddleware,
} from "pages/auth/services/api"
import { setUserInfoAction } from "pages/auth/stores/actions"
import { emptyUserInfo, MFAMethod } from "pages/auth/types"
import { useEffect, useState } from "react"
import { configureStore } from "stores/configureStore"
import { STATUS_RESPONSE } from "types"
import { PageLayoutProjectProps } from "../projects/types"
import { twMerge } from "tailwind-merge"
import MenuLeft from "./MenuLeft"
import ModalCustom from "components/ModalCustom"
import Button from "components/Button/Button"
import CheckedDefault from "components/Checked/CheckedDefault"
import { useBoolean } from "helpers/hooks"
import {
  createAgreementWithLastestDocument,
  getReleaseNotesMiddleware,
  updateSeenNotesMiddleware,
} from "./services/api"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { Link, useLocation } from "react-router-dom"
import { umamiTracking } from "helpers/utils"
import { EVENT } from "constants/events"
import ReleaseNotes from "./ReleaseNotes"
import { WarningEnhanceAuthenticationModal } from "./modal/WarningEnhanceAuthenticationModal"
import { includes } from "lodash"

const PageLayout = (props: PageLayoutProjectProps) => {
  const {
    children,
    childrenHeader,
    heightHeader = 64,
    minWidth = "min-w-[1100px]",
    containerClass,
    containerStyles,
    contentClass,
    contentStyles,
    headerClass,
    headerStyles,
  } = props

  const userInfo = useAppSelector((state) => state.userInfo)
  const unreadBuild = useAppSelector((state) => state.unreadBuild)

  const unreadConversation = useAppSelector((state) => state.unreadConversation)

  const checkbox = useBoolean(false)
  const [noteInfo, setNoteInfo] = useState<{
    notes: string
    created_at: string
  }>({ notes: "", created_at: "" })
  const seenNote = useBoolean(true)
  const isWarningEnhanceAuthentication = useBoolean(false)
  const location = useLocation()
  useEffect(() => {
    if (userInfo.id) {
      return
    }
    getUserMiddleware((type) => {
      if (type === STATUS_RESPONSE.ERROR) {
        localStorage.clear()
        configureStore.dispatch(setUserInfoAction(emptyUserInfo))
        pushTo(PATH.login)
      }
    })
    getUnreadBuildMiddleware()
    getUnreadConversationMiddleware()
    getReleaseNotes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!userInfo?.id) return

    const googleAuthenticator = userInfo.mfaMethods?.filter(
      (method) => Number(method) !== MFAMethod.email // The authenticator app
    )

    if (
      googleAuthenticator?.length ||
      includes([PATH.general, PATH.changePhoneNumber], location.pathname)
    ) {
      isWarningEnhanceAuthentication.setValue(false)
      return
    }
    isWarningEnhanceAuthentication.setValue(true)
  }, [userInfo?.id, location.pathname])

  const onCloseNotes = () => {
    updateSeenNotesMiddleware()
      .then((_res) => {
        seenNote.setValue(true)
      })
      .catch((_error) => {
        seenNote.setValue(true)
      })
  }
  const getReleaseNotes = () => {
    getReleaseNotesMiddleware()
      .then((res) => {
        setNoteInfo({ notes: res.log, created_at: res.created_at })
        seenNote.setValue(false)
      })
      .catch((_error) => {
        seenNote.setValue(true)
      })
  }
  const onConfirm = () => {
    if (!checkbox.value) return
    createAgreementWithLastestDocument()
      .then(() => {
        configureStore.dispatch(
          setUserInfoAction({ ...userInfo, has_agreed_latest_document: true })
        )
      })
      .catch(() => {
        return (
          <LabelNotificationPage
            messenger="Something went wrong. Please try again!"
            type="error"
          />
        )
      })
  }

  const handleEnhanceAuthentication = () => {
    pushTo("/generals", undefined, { tab: 2 })
  }

  const renderModal = () => {
    if (!userInfo.has_agreed_latest_document) {
      return (
        <ModalCustom
          label="Confirmation"
          widthModal={570}
          bodyChildren={
            <div
              className="grid text-sm mb-[12px] font-medium gap-y-2 text-center sm:text-center md:text-justify "
              style={{ color: "black" }}
            >
              <p className=" pb-[5px]">
                {" "}
                We have updated our Terms and Conditions, Privacy Policy
              </p>
              <div className="flex">
                {" "}
                <CheckedDefault
                  checked={checkbox.value}
                  onClick={() => checkbox.setValue(!checkbox.value)}
                />{" "}
                <p className="pl-[18px] sm:pl-[26px]">
                  {" "}
                  By clicking on this button, I agree with Tracelium's{" "}
                  <Link
                    to={{
                      pathname: PATH.termsAndServices,
                    }}
                    target="_blank"
                    style={{ color: "blue", textDecoration: "underline" }}
                    onClick={() => {
                      umamiTracking(EVENT.TERM)
                    }}
                  >
                    {" "}
                    Terms and Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    to={{
                      pathname: PATH.policies,
                    }}
                    target="_blank"
                    style={{ color: "blue", textDecoration: "underline" }}
                    onClick={() => {
                      umamiTracking(EVENT.POLICY)
                    }}
                  >
                    {" "}
                    Privacy Policy
                  </Link>
                  {"."}
                </p>
              </div>
              {!checkbox.value ? (
                <div style={{ color: "red" }} className="text-center pb-[30px]">
                  Please Check and accept our Terms and Conditions, Privacy
                  Policy
                </div>
              ) : (
                <div className="pb-[30px] h-[70px] sm:h-[50px]"></div>
              )}
              <div className="flex items-center justify-center ">
                <Button
                  widthBtn={"178px"}
                  title="Accept"
                  colorBtn="yellow"
                  onClick={onConfirm}
                  disabled={!checkbox.value}
                ></Button>
              </div>
            </div>
          }
        />
      )
    }
    if (!seenNote.value) {
      return <ReleaseNotes onCloseNotes={onCloseNotes} noteInfo={noteInfo} />
    }
    if (isWarningEnhanceAuthentication.value) {
      return (
        <WarningEnhanceAuthenticationModal
          onSetUpGoogleAuthenticator={handleEnhanceAuthentication}
        />
      )
    }
    return null
  }
  return (
    <div
      className={twMerge(
        "height-screen min-w-full flex flex-col md:flex-row relative overflow-hidden",
        containerClass
      )}
      style={containerStyles}
    >
      <MenuLeft
        userInfo={userInfo}
        unreadBuild={unreadBuild}
        unreadConversation={unreadConversation}
      />

      <div
        className={`flex flex-col flex-1 b-grayWhite2 overflow-auto md:${minWidth}`}
      >
        {/* header */}
        {childrenHeader ? (
          <div
            className={twMerge("border-b border-[#E4E4E4]", headerClass)}
            style={{
              background: "#FFFFFF",
              minHeight: heightHeader,
              ...headerStyles,
            }}
          >
            {childrenHeader}
          </div>
        ) : null}

        {/* main content */}
        <div
          className={twMerge(
            "overflow-auto h-full bg-grayWhite2",
            contentClass
          )}
          style={contentStyles}
        >
          {children}
        </div>

        {renderModal()}
      </div>
    </div>
  )
}

export default PageLayout
