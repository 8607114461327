import Button from "components/Button/Button"
import ModalCustom from "components/ModalCustom"

interface Props {
  onCloseModal: () => void
  openModal: boolean
  invitation: {
    invitee: {
      name: string
      email: string
      isSent: boolean
    }
    ccs: {
      email: string
      isSent: boolean
    }[]
  }
  onSubmitSendInvitation: (title: string, email: string[]) => () => void
}
export const FormSendInvitation = (props: Props) => {
  const { onCloseModal, onSubmitSendInvitation, invitation } = props

  const getEmailsBySentStatus = (ccs, isSent) => {
    return ccs
      .filter((item) => item.isSent === isSent)
      .map((item) => item.email)
  }

  const getInviteeEmailBySentStatus = (invitee, isSent) => {
    return invitee.isSent === isSent ? invitee.email : null
  }

  const renderBody = () => {
    const emailSend = getEmailsBySentStatus(invitation.ccs, false)
    const emailReSend = getEmailsBySentStatus(invitation.ccs, true)

    const inviteeEmailToSend = getInviteeEmailBySentStatus(
      invitation.invitee,
      false
    )
    const inviteeEmailToReSend = getInviteeEmailBySentStatus(
      invitation.invitee,
      true
    )

    if (inviteeEmailToSend) {
      emailSend.push(inviteeEmailToSend)
    } else if (inviteeEmailToReSend) {
      emailReSend.push(inviteeEmailToReSend)
    }

    const emailsToSend = emailSend.length ? emailSend : emailReSend
    const title = emailSend.length ? "Send" : "Resend"

    return (
      <div className="flex flex-col mt-[4px]">
        <div className="flex flex-col overflow-y-auto max-h-[452px]">
          {renderItem(false, "Invitees", [invitation.invitee])}
          {invitation.ccs.length
            ? renderItem(true, "Cc", invitation.ccs)
            : null}
        </div>

        <div className="pt-[12px] pr-[16px]">
          <Button
            widthBtn={"100%"}
            title={`${title} all`}
            colorBtn="yellow"
            onClick={onSubmitSendInvitation(title, emailsToSend)}
          />
        </div>
      </div>
    )
  }

  const renderItem = (
    onlyEmail: boolean,
    label: string,
    data: {
      name?: string
      email: string
      isSent: boolean
    }[]
  ) => {
    return (
      <div className="flex flex-col mb-[16px] pr-[16px]">
        <p className="text-[14px] font-semibold leading-[24px] text-[#111111]">
          {label}
        </p>
        <div className="mt-[4px] rounded-[6px] border border-[#E4E4E4]">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-between border-b min-h-[46px] px-[12px]"
                style={{
                  borderColor:
                    index === data.length - 1 ? "transparent" : "#E4E4E4",
                }}
              >
                <div className="flex items-center">
                  {onlyEmail ? (
                    <p className="text-[13px] font-normal leading-[22px] text-[#111111]">
                      {item.email}
                    </p>
                  ) : (
                    <>
                      <p className="text-[13px] font-normal leading-[22px] text-[#111111]">
                        {item.name}
                      </p>
                      <div className="w-[4px] h-[4px] bg-[#7A7A7A] mx-[8px] rounded-full"></div>
                      <p className="text-[13px] font-normal leading-[22px] text-[#7A7A7A]">
                        {item.email}
                      </p>
                    </>
                  )}
                </div>
                <p
                  className="text-[13px] font-normal leading-[22px] cursor-pointer hover:underline"
                  onClick={onSubmitSendInvitation(
                    item.isSent ? "Resend" : "Send",
                    [item.email]
                  )}
                  style={{
                    color: item.isSent ? "#0A5AF5" : "#7A7A7A",
                  }}
                >
                  {item.isSent ? "Resend" : "Sent"}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <ModalCustom
      label="Resend invitation"
      handleChangeButton={() => onCloseModal}
      bodyChildren={renderBody()}
      widthModal={480}
      styleRoot={{
        paddingRight: 0,
      }}
      styleHeaderModal={{
        paddingRight: 16,
      }}
    />
  )
}
