import React, { useContext } from "react"
import {
  checkPermissionPage,
  formatDateForConversationMessage,
  getDifferenceInDays,
} from "helpers/utils"
import { ProjectComponentDetail } from "pages/project-component/types"
import TypeComponent from "pages/project-component/molecules/TypeComponent"
import { ReactComponent as IconChecked } from "assets/images/icons/icon-checked-build.svg"
import { ReactComponent as IconCheckedActive } from "assets/images/icons/icon-checked-build-active.svg"
import { ReactComponent as IconRemove } from "assets/images/icons/icon-remove-component-build.svg"
import { PermissionProjectBuildPage } from "../contexts/PermissionProjectBuildPage.context"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import { STATUS_BUILD } from "components/Status/types"
import DotCard from "pages/conversations/atoms/DotCard"
import LabelStatusBuild from "pages/project-component/molecules/LabelStatusBuild"

interface Props {
  item: ProjectComponentDetail
  isActive?: boolean
  onSelectComponent?: (
    id: string,
    isValidToBuild: boolean,
    idProjectBuildComponent: string,
    typeKey: string,
    idProjectComponentHistory: string
  ) => (event) => void
  isRemove?: boolean
  onRemoveComponent?: (idProjectBuildComponent: string) => (event) => void
  isHoverCard?: boolean
  isSync?: boolean
  isDeleted?: boolean
  onSyncData?: (newItem: ProjectComponentDetail) => (event) => void
  onReShareKey?: (newItem: ProjectComponentDetail) => (event) => void
  isView?: boolean
  isShowInvitee?: boolean
  isShowStatus?: boolean
  canSync?: boolean
  isShowReShare?: boolean
}
const ComponentDetailInBuild = (props: Props) => {
  const {
    item,
    isActive,
    isRemove,
    isHoverCard,
    isSync,
    isDeleted,
    isView = false,
    onSelectComponent,
    onRemoveComponent,
    onSyncData,
    onReShareKey,
    isShowInvitee,
    isShowStatus = false,
    canSync,
    isShowReShare,
  } = props
  const { archiveProject, archiveBuild } = useContext(
    PermissionProjectBuildPage
  )
  const renderIcon = () => {
    if (isView || isDeleted) {
      return null
    }
    if (isRemove) {
      if (
        checkPermissionPage({
          project: archiveProject,
          build: archiveBuild,
        })
      ) {
        return
      }
      return (
        <IconRemove
          onClick={
            onRemoveComponent
              ? onRemoveComponent(item.project_build_component_id ?? "")
              : undefined
          }
        />
      )
    }
    if (isSync) {
      if (
        checkPermissionPage({
          project: archiveProject,
          build: archiveBuild,
        })
      ) {
        return
      }
      return (
        <ActionIconHistory
          icon="system-data"
          tooltip={
            canSync
              ? "Update to the latest version from the master branch"
              : "The component has already been updated to the most recent version."
          }
          customStyleIcon={{
            width: 24,
            height: 24,
          }}
          disabled={!canSync}
          onClick={
            onSyncData && canSync
              ? onSyncData(item)
              : (event) => {
                  event.preventDefault()
                  event.stopPropagation()
                }
          }
          styleIcon={{
            marginRight: 0,
          }}
        />
      )
    }
    if (!item.is_valid_to_build) {
      return null
    }
    if (isActive) {
      return <IconCheckedActive />
    }
    return <IconChecked />
  }
  const classHoverCard = () => {
    if (isHoverCard) {
      return true
    }
    return item.is_valid_to_build
  }
  const renderInfoInvitee = () => {
    if (isShowInvitee && item.invitee?.email) {
      return (
        <div className="flex flex-col">
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: "#111111",
            }}
          >
            {item.invitee.name}
          </p>
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: "#7A7A7A",
            }}
          >
            {item.invitee.email}
          </p>
        </div>
      )
    }
    return (
      <div className="flex">
        <p
          className="font-medium text-time"
          style={{
            color: "#7A7A7A",
            fontSize: 12,
            lineHeight: "18px",
          }}
        >
          {item?.updated_at
            ? `Updated ${formatDateForConversationMessage(item.updated_at)}`
            : "No update"}
        </p>
      </div>
    )
  }

  const renderExpireDate = () => {
    if (!item.shareKeyExpiredAt) {
      return null
    }
    const differenceInDays = getDifferenceInDays(item.shareKeyExpiredAt)
    let color = "#56A012"
    if (differenceInDays === 1) {
      color = "#EA4545"
    }
    if (differenceInDays === 2) {
      color = "#E87A16"
    }

    return (
      <p
        className={`text-[12px] font-medium leading-[18px]`}
        style={{
          color: color,
        }}
      >
        {`Shared version expires in ${differenceInDays} day(s)`}
      </p>
    )
  }

  return (
    <div
      onClick={
        onSelectComponent && !isDeleted
          ? onSelectComponent(
              item.id,
              item.is_valid_to_build,
              item.project_build_component_id ?? "",
              item.type.key,
              item.project_component_history_id
            )
          : undefined
      }
      className={`rounded-md px-3 pt-[9px] ${
        classHoverCard() && !isDeleted ? "hover-component-detail-in-build" : ""
      } ${
        isDeleted
          ? " bg-neutral3 archived-project-component"
          : "bg-white cursor-pointer"
      }`}
      style={{
        border: `1px solid ${isActive ? "#56a012" : "#E4E4E4"}`,
        boxShadow: isActive ? "0px 0px 0px 4px rgb(86 160 18 / 10%)" : "none",
      }}
    >
      <div className="flex justify-between mb-[21px]">
        <TypeComponent label={item.type?.key || ""} />
        {renderIcon()}
      </div>
      <div className="flex items-center">
        <p className="font-medium text-code text-xs text-brown">{item.code}</p>
        {item.version ? (
          <React.Fragment>
            <div
              style={{
                width: 4,
                height: 4,
                backgroundColor: "#7A7A7A",
                borderRadius: "50%",
                margin: "0px 4px",
              }}
            />
            <p
              className="font-medium text-code"
              style={{
                fontSize: 12,
                color: "#7A7A7A",
                lineHeight: "18px",
              }}
            >
              {item.version}
            </p>
          </React.Fragment>
        ) : null}
      </div>
      <div className="flex items-center mt-1">
        <p
          className="font-semibold text-name"
          style={{
            fontSize: 14,
            color: "#111111",
            lineHeight: "24px",
          }}
        >
          {item.name}
        </p>
        {item.last_version_status &&
        isShowStatus &&
        item.last_version_status === STATUS_BUILD.DRAFT ? (
          <React.Fragment>
            <DotCard />
            <LabelStatusBuild status={item.last_version_status} />
          </React.Fragment>
        ) : null}
      </div>
      <div
        className="flex flex-col"
        style={
          isShowInvitee && item.invitee?.email
            ? {
                marginTop: 18,
                marginBottom: 4,
              }
            : {
                marginTop: 25,
                paddingBottom: 15,
              }
        }
      >
        {renderExpireDate()}
        <div className="flex justify-between items-center w-full">
          {renderInfoInvitee()}
          {isShowReShare ? (
            <ActionIconHistory
              classIcon="hidden md:block"
              icon="share-key"
              tooltip={"Re-share component data to invitees."}
              onClick={
                onReShareKey
                  ? onReShareKey(item)
                  : (event) => {
                      event.preventDefault()
                      event.stopPropagation()
                    }
              }
              styleIcon={{
                marginRight: 0,
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}
export default ComponentDetailInBuild
