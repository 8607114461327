import React from "react"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import {
  UnreadConversation,
  emptyUnreadBuild,
  emptyUnreadConversation,
} from "./../../conversations/conversations.type"
import Axios, { AxiosResponse, AxiosError } from "axios"
import {
  SignUpRequest,
  LoginRequest,
  ForgetPasswordRequest,
  PasswordResetRequest,
  UserInfo,
  ChangePasswordRequest,
  InformationUserRequest,
  PrivacyDetail,
  CountryDataProp,
  InviteSentSignUpRequest,
  LoginResponse,
  EnableEncryptionRequest,
  CompanyRole,
  IsEnabledMFAResponse,
  MFAMethod,
} from "../types"
import { STATUS_RESPONSE } from "types"
import {
  setUnreadBuildAction,
  setUnreadConversationAction,
  setUserInfoAction,
} from "../stores/actions"
import { configureStore } from "stores/configureStore"
import { UnreadBuild } from "pages/conversations/conversations.type"
import { encryptionHelper } from "helpers/encryption"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { toast } from "react-toastify"
import { includes } from "lodash"
const {
  generateMasterKeyAndPasswordHashed,
  decryptVaultKeyAndStore,
  decryptPrivateKeyAndStore,
} = encryptionController()

export const signUpMiddleware = async (
  request: SignUpRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  const generatedBackupKey = await Axios.get("/api/auth/backup-key")
  const publicAndPrivateTemporarily = await Axios.get<{
    data: {
      privateKey: string
      publicKey: string
    }
  }>("/api/auth/decrypt-private-key", {
    params: {
      email: request.email,
    },
  })

  const { backupKey, encryptedBackupKey } = generatedBackupKey.data.data
  const { password, email, backupKeyCode } = request
  const { masterKey, passwordHash } = await generateMasterKeyAndPasswordHashed(
    password,
    email
  )

  let newPrivateKey = ""
  let newPublicKey = ""
  if (
    publicAndPrivateTemporarily.data.data.privateKey &&
    publicAndPrivateTemporarily.data.data.publicKey
  ) {
    newPrivateKey = publicAndPrivateTemporarily.data.data.privateKey
    newPublicKey = publicAndPrivateTemporarily.data.data.publicKey
  } else {
    const generateKeyLocal = await encryptionHelper.generateKeyPair()
    newPrivateKey = generateKeyLocal.privateKey
    newPublicKey = generateKeyLocal.publicKey
  }
  if (!newPrivateKey || !newPublicKey) {
    callback(STATUS_RESPONSE.ERROR, "Generate public and private failed!")
    return
  }
  const vaultKey = encryptionHelper.createRandomKey()

  const encryptedPrivateKey = encryptionHelper.encrypt(
    vaultKey,
    newPrivateKey
  ) as string

  const encryptedVaultkey = encryptionHelper.encrypt(
    masterKey,
    vaultKey
  ) as string

  const encryptedBackUpkey = encryptionHelper.encrypt(
    backupKey,
    vaultKey
  ) as string

  const encryptedBackupKeyCode = encryptionHelper.encrypt(
    backupKeyCode,
    vaultKey
  )

  const newRequest = {
    ...request,
    password: passwordHash,
    encryptedVaultkey,
    encryptedBackUpkey,
    encryptedKMSBackupKey: encryptedBackupKey,
    encryptedPrivateKey,
    publicKey: newPublicKey,
    encryptedBackupKeyCode,
  }
  Axios.post(`/api/auth/register`, newRequest)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Sign up failed!"
      )
    })
}

export const checkEmailExist = async (email: string) => {
  try {
    await Axios.get(`/api/auth/check-email`, {
      params: {
        email,
      },
    })
    return false
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      toast(
        React.createElement(LabelNotificationPage, {
          messenger: MESSENGER_NOTIFICATION.EMAIL_EXIST,
          type: "error",
        })
      )
      return true
    }

    return false
  }
}
export const onAfterLogin = (response: any) => {
  //decrypt encrypted vaultKey
  let isEncrypted = localStorage.getItem("enabled_encrypted")
  let generatedMasterKey = localStorage.getItem(EncryptionKeys.userMasterKey)
  if (isEncrypted === "1" && generatedMasterKey) {
    decryptVaultKeyAndStore(response.data.encryptedVaultkey, generatedMasterKey)
  }

  localStorage.setItem("isDoneSyncEncrypted", response.data.isDoneSyncEncrypted)

  if (response.data.encryptedVaultkey && response.data.projectKeys) {
    const projectEncryptionKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
    )
    if (response.data.projectKeys) {
      const userVaultKey = localStorage.getItem(
        EncryptionKeys.userVaultKey
      ) as string
      const decryptedKeys = response.data.projectKeys.reduce((pre, cur) => {
        return {
          ...pre,
          [cur.id]: encryptionHelper.decrypt(userVaultKey, cur.encryptedKey),
        }
      }, {})
      localStorage.setItem(
        EncryptionKeys.projectEncryptionKeys,
        JSON.stringify({
          ...projectEncryptionKeys,
          ...decryptedKeys,
        })
      )
    }
  }
  if (generatedMasterKey) {
    decryptPrivateKeyAndStore(
      response.data.encryptedPrivateKey,
      response.data.user_id,
      generatedMasterKey,
      response.data.buildKeys
    )
  }

  return response.data
}
export const loginMiddleware = async (request: LoginRequest) => {
  const { password, email } = request
  let newRequest = request
  let generatedMasterKey = ""
  const isEncryptedUser: AxiosResponse<{
    data: {
      is_encrypted: boolean
    }
  }> = await Axios.get("/api/user/is-encrypted", {
    params: {
      email,
    },
  })
  if (isEncryptedUser.data.data.is_encrypted) {
    const { masterKey, passwordHash } =
      await generateMasterKeyAndPasswordHashed(password, email)
    generatedMasterKey = masterKey
    localStorage.setItem(EncryptionKeys.userMasterKey, masterKey)
    newRequest = { ...request, password: passwordHash }
  }

  localStorage.setItem(
    "enabled_encrypted",
    isEncryptedUser.data.data.is_encrypted ? "1" : "0"
  )

  return Axios.post<LoginResponse>(`/api/auth/login`, newRequest).then(
    (response) => {
      return onAfterLogin(response)
    }
  )
}

export const enableEncryptionMiddleware = async (
  request: EnableEncryptionRequest
) => {
  return Axios.post("/api/user/enable-encryption", request)
}

export const forgetPasswordMiddleware = (
  request: ForgetPasswordRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/forgot-password`, request)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Forgot password failed!"
      )
    })
}
export const passwordResetMiddleware = (
  request: PasswordResetRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/reset-password`, request)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Password Reset failed!"
      )
    })
}

export const verifyEmailMiddleware = (
  verificationToken: string,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/verify/${verificationToken}`)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((errorVerify) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorVerify.response?.data?.message ?? "Email verification failed!"
      )
    })
}
export const reSendEmailMiddleware = (
  type: string,
  email: string,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/resend-email/${type}/${email}`)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((errorResend) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorResend.response.data?.message ?? "Email verification failed!"
      )
    })
}

export const getUserMiddleware = (
  callback?: (type: STATUS_RESPONSE) => void
) => {
  Axios.get(`/api/user/me`)
    .then((response: AxiosResponse<{ data: UserInfo }>) => {
      configureStore.dispatch(setUserInfoAction(response.data.data))
      localStorage.setItem("user_id", response.data.data.id)
      localStorage.setItem("userEmail", response.data.data.email)
      localStorage.setItem("userFullName", response.data.data.fullname)
      localStorage.setItem(
        "enabled_encrypted",
        response.data.data.is_encrypted ? "1" : "0"
      )

      // setting timezone
      if (response.data.data.timezone) {
        localStorage.setItem("timezone", response.data.data.timezone)
      }
      if (callback) {
        callback(STATUS_RESPONSE.SUCCESS)
      }
    })
    .catch((_error: AxiosError) => {
      if (callback) {
        callback(STATUS_RESPONSE.ERROR)
      }
    })
}
export const getUnreadBuildMiddleware = () => {
  Axios.get("/api/conversation/unread-build")
    .then((response: AxiosResponse<{ data: UnreadBuild }>) => {
      configureStore.dispatch(setUnreadBuildAction(response.data.data))
    })
    .catch((_error: AxiosError) => {
      configureStore.dispatch(setUnreadBuildAction(emptyUnreadBuild))
    })
}

export const getUnreadConversationMiddleware = () => {
  Axios.get("/api/conversation/unread")
    .then((response: AxiosResponse<{ data: UnreadConversation }>) => {
      configureStore.dispatch(setUnreadConversationAction(response.data.data))
    })
    .catch((_error: AxiosError) => {
      configureStore.dispatch(
        setUnreadConversationAction(emptyUnreadConversation)
      )
    })
}

export const changePasswordMiddleware = async (
  request: ChangePasswordRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  let payload = request
  const enableEncrypted = localStorage.getItem("enabled_encrypted")
  if (enableEncrypted === "1") {
    const { generatePasswordHashed } = encryptionController()
    payload = {
      currentPassword: await generatePasswordHashed(request.currentPassword),
      newPassword: await generatePasswordHashed(request.newPassword),
      confirmedNewPassword: await generatePasswordHashed(
        request.confirmedNewPassword
      ),
    }
  }

  Axios.post(`/api/user/change-password`, payload)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response.data?.message ?? "Change password failed!"
      )
    })
}

export const updateInformationMiddleware = (
  request: InformationUserRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.put(`/api/user/update`, request)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response.data?.message ?? "Update Information failed!"
      )
    })
}

export const getFAQMiddleware = async () => {
  const response: AxiosResponse<{
    data: PrivacyDetail
  }> = await Axios.get(`/api/about/faq`)
  return response.data.data
}

export const getPrivacyPolicyMiddleware = async () => {
  const response: AxiosResponse<{
    data: PrivacyDetail
  }> = await Axios.get(`/api/about/privacy-policy`)
  return response.data.data
}

export const getTermOfUseMiddleware = async () => {
  const response: AxiosResponse<{
    data: PrivacyDetail
  }> = await Axios.get(`/api/about/term-of-use`)
  return response.data.data
}

// Order API
export const getCountryMiddleware = async (params?: {
  filter: { country: string }
}) => {
  const response: AxiosResponse<{
    data: CountryDataProp[]
  }> = await Axios.get(`/api/order/country`, { params })
  return response.data.data
}

export const getStatesMiddleware = async (params: {
  filter?: { state: string }
  country: string
  countryCode: string
}) => {
  const response: AxiosResponse<{
    data: string[]
  }> = await Axios.get(`/api/order/state`, { params })
  return response.data.data
}

export const getCitiesMiddleware = async (params: {
  filter?: { city: string }
  state?: string
  country: string
  countryCode: string
}) => {
  const response: AxiosResponse<{
    data: { postalCode: string; city: string }[]
  }> = await Axios.get(`/api/order/city`, { params })
  return response.data.data
}
export const checkUserIsActivatedMiddleware = async (email: string) => {
  const response = await Axios.get(`/api/auth/is-activated?email=${email}`)
  return response.data.isActivated
}
export const inviteSentSignupMiddleware = async (
  verificationToken: string,
  request: InviteSentSignUpRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  const generatedBackupKey = await Axios.get("/api/auth/backup-key")
  const publicAndPrivateTemporarily = await Axios.get<{
    data: {
      privateKey: string
      publicKey: string
    }
  }>("/api/auth/decrypt-private-key", {
    params: {
      email: request.email,
    },
  })
  const { backupKey, encryptedBackupKey } = generatedBackupKey.data.data
  const { email, password, backupKeyCode, ...rest } = request
  const { masterKey, passwordHash } = await generateMasterKeyAndPasswordHashed(
    password,
    email
  )
  let newPrivateKey = ""
  let newPublicKey = ""
  if (
    publicAndPrivateTemporarily.data.data.privateKey &&
    publicAndPrivateTemporarily.data.data.publicKey
  ) {
    newPrivateKey = publicAndPrivateTemporarily.data.data.privateKey
    newPublicKey = publicAndPrivateTemporarily.data.data.publicKey
  } else {
    const generateKeyLocal = await encryptionHelper.generateKeyPair()
    newPrivateKey = generateKeyLocal.privateKey
    newPublicKey = generateKeyLocal.publicKey
  }
  if (!newPrivateKey || !newPublicKey) {
    callback(STATUS_RESPONSE.ERROR, "Generate public and private failed!")
    return
  }
  const vaultKey = encryptionHelper.createRandomKey()
  const encryptedPrivateKey = encryptionHelper.encrypt(
    vaultKey,
    newPrivateKey
  ) as string
  const encryptedVaultKey = encryptionHelper.encrypt(
    masterKey,
    vaultKey
  ) as string
  const encryptedBackupKeyBackupVersion = encryptionHelper.encrypt(
    backupKey,
    vaultKey
  ) as string

  const encryptedBackupKeyCode = encryptionHelper.encrypt(
    backupKeyCode,
    vaultKey
  )

  const newRequest = {
    ...rest,
    email,
    password: passwordHash,
    confirmedPassword: passwordHash,
    encryptedVaultKey,
    encryptedBackupKey: encryptedBackupKeyBackupVersion,
    encryptedKMSBackupKey: encryptedBackupKey,
    encryptedPrivateKey,
    publicKey: newPublicKey,
    encryptedBackupKeyCode,
  }
  Axios.post(
    `/api/auth/create-password-and-verify/${verificationToken}`,
    newRequest
  )
    .then(() => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? " Invite Sent Sign up failed!"
      )
    })
}

export const settingTwoFactor = async (
  type: "enable" | "disable",
  method: MFAMethod
) => {
  return Axios.post(`/api/auth/multi-factor/${type}`, { method }).then(
    (response) => {
      if (
        type === "disable" ||
        includes([MFAMethod.email, MFAMethod.sms], method)
      ) {
        getUserMiddleware()
      }
      return response.data
    }
  )
}
export const verifySettingMFA = async (
  payload: any,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  return Axios.post(`/api/auth/multi-factor/verify-setting`, payload)
    .then(() => {
      getUserMiddleware()
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? " Setting MFA failed!"
      )
    })
}
export const resendCodeTwoFactor = async (
  token: string,
  method: MFAMethod,
  isResetPassword?: boolean
) => {
  const additionalQuery = isResetPassword
    ? `&isResetPassword=${isResetPassword}`
    : ""
  return Axios.post<LoginResponse>(
    `/api/auth/multi-factor/resend-code?method=${method}&token=${token}${additionalQuery}`
  ).then((response) => {
    return response.data
  })
}
export const verifyTwoFactor = async (
  code: string,
  token: string,
  method: MFAMethod,
  isResetPassword?: boolean
) => {
  let payload: any = { code, method }
  if (isResetPassword) {
    payload = { ...payload, isResetPassword }
  }
  return Axios.post<LoginResponse>(
    `/api/auth/multi-factor/verify?token=${token}`,
    payload
  ).then((response) => {
    onAfterLogin(response)
    return response.data
  })
}
export const checkIsEnabledMFAMiddleware = async (email: string) => {
  return Axios.get<IsEnabledMFAResponse>(
    `/api/auth/multi-factor/is-enabled/${email}`
  ).then((response) => {
    return response.data.data
  })
}

export const getCompanyRoles = async () => {
  const res = await Axios.get<{ data: CompanyRole[] }>(
    "/api/auth/get-company-roles"
  )
  return res.data.data
}

export const getTokenByInvitee = async (bodyRequest: any) => {
  try {
    const res = await Axios.post<{ token: string }>(
      "/api/auth/get-token",
      bodyRequest
    )
    return res.data.token
  } catch (error) {
    return ""
  }
}

export const generateDataKeyPairMiddleware = async () => {
  const res = await Axios.get<{
    data: {
      publicKey: string
      encryptedPrivateKey: string
      keyId: string
    }
  }>("/api/user/generate-data-key-pair")
  return res.data.data
}

export const postGenerateNewRecoveryCodeMiddleware = async (
  encryptedBackupKeyCode: string
) => {
  const res = await Axios.post("/api/auth/encrypted-backup-key-code", {
    encryptedBackupKeyCode,
  })
  return res.data.data
}

export const postSendSMSOTPMiddleware = async (phoneNumber: string) => {
  const res = await Axios.post("/api/auth/send-sms-opt-code", {
    phoneNumber,
  })
  return res.data.data
}

export const postChangePhoneNumberMiddleware = async (payload: {
  phoneNumber: string
  password: string
  code: string
}) => {
  const res = await Axios.post("/api/auth/change-phone-number", payload)
  return res.data.data
}

export const postVerifyPhoneNumberMiddleware = async (payload: {
  code: string
}) => {
  const res = await Axios.post("/api/auth/verify-phone-number", payload)
  return res.data.data
}
