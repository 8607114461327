import React, { useEffect } from "react"
import PageLayout from "pages/layout/PageLayout"
import ListTab from "components/Tab"
import { useBoolean, useNumber, useString, useWindowSize } from "helpers/hooks"
import ChangePasswordCard from "./organisms/ChangePasswordCard"
import InformationUserCard from "./organisms/InformationUserCard"
import TwoFactorSetting from "./organisms/TwoFactorSetting"
import { useAppSelector } from "hooks/useApp"
import { ReactComponent as IconNoAvatar } from "assets/images/icons/icon-no-avatar.svg"
import { ReactComponent as IconUpdateAvatar } from "assets/images/icons/icon-update-avatar.svg"
import { convertUrlBase64, getBase64, showImageUrl } from "helpers/utils"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { getUserMiddleware, updateInformationMiddleware } from "./services/api"
import { STATUS_RESPONSE } from "types"
import { API_URL } from "config/environments"
import { uniqueId } from "lodash"
import Settings from "pages/settings/Settings"
import { useLocation } from "react-router"

const General = () => {
  const location = useLocation() as any
  const locationStateTab = location.state?.tab || 0
  const activeTab = useNumber(locationStateTab)
  const userInfo = useAppSelector((state) => state.userInfo)
  const keyInputFile = useString()
  const isLoading = useBoolean()
  const size = useWindowSize()
  const handleClickTabItem = (indexTab: number) => () => {
    activeTab.setValue(indexTab)
  }

  useEffect(() => {
    activeTab.setValue(locationStateTab)
  }, [locationStateTab])
  const renderBody = () => {
    switch (activeTab.value) {
      case 0:
        return <InformationUserCard />
      case 1:
        return <ChangePasswordCard />
      case 2:
        return <TwoFactorSetting />
      case 3:
        return <Settings />
      default:
        return (
          <div className="w-full h-full flex justify-center items-center grow">
            <p
              style={{
                fontWeight: 700,
                fontSize: 20,
                lineHeight: "32px",
                color: " #C7C7C7",
              }}
            >
              Coming soon
            </p>
          </div>
        )
    }
  }
  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentFiles: any = []
    if (event.target.files) {
      for (const [, file] of Object.entries(event.target.files)) {
        currentFiles.push(file)
      }
    }
    keyInputFile.setValue(uniqueId("general_"))
    isLoading.setValue(true)
    getBase64(currentFiles[0])
      .then((data) => {
        callAPIUpdateAvatar(convertUrlBase64(String(data)))
      })
      .catch(() => {
        isLoading.setValue(false)
        toast(
          <LabelNotificationPage
            messenger={MESSENGER_NOTIFICATION.IMAGE_INVALID}
            type="error"
          />
        )
      })
  }
  const callAPIUpdateAvatar = (newData: string) => {
    updateInformationMiddleware(
      {
        avatar: newData,
        fullname: userInfo.fullname,
      },
      (type, messenger) => {
        if (type === STATUS_RESPONSE.SUCCESS) {
          getUserMiddleware((typeStatus) => {
            if (typeStatus) isLoading.setValue(false)
          })

          toast(
            <LabelNotificationPage
              messenger={MESSENGER_NOTIFICATION.UPDATING_INFORMATION_SUCCESS}
              type="success"
            />
          )
        } else {
          isLoading.setValue(false)
          toast(
            <LabelNotificationPage messenger={messenger ?? ""} type="error" />
          )
        }
      }
    )
  }
  const URLAPIAvatar =
    userInfo && userInfo.avatar && userInfo.avatar.includes("public/")
      ? API_URL
      : undefined

  const removeProfileIcon = () => {
    callAPIUpdateAvatar("")
  }

  return (
    <PageLayout heightHeader={0} minWidth="min-w-[500px]">
      <div className="flex w-full h-full">
        <div
          className={`flex w-full justify-center ${
            size.height > 683 ? "items-center" : ""
          }`}
        >
          <div className="relative m-6 pt-12 w-[440px] hide-scroll">
            <div
              className="w-full bg-white pt-5 px-3 pb-3 flex flex-col"
              style={{
                border: "1px solid #E4E4E4",
                borderRadius: 6,
                minHeight: 650,
              }}
            >
              <div
                className="flex flex-col"
                style={{
                  paddingLeft: 150,
                  minHeight: 48,
                }}
              >
                <p
                  className="font-semibold"
                  style={{
                    fontSize: 16,
                    lineHeight: "26px",
                    color: "#111111",
                  }}
                >
                  {userInfo.fullname}
                </p>
                <p
                  className="font-medium mt-1"
                  style={{
                    fontSize: 12,
                    lineHeight: "18px",
                    color: "#7A7A7A",
                  }}
                >
                  {userInfo.id}
                </p>
              </div>
              <p className="mt-6 font-light italic text-xs">
                <span className="text-error"> *</span> Avatar should be square
                128x128 pixels.
              </p>
              <div className="flex mt-6 mb-3">
                <ListTab
                  listTab={["My Profile", "Account", "Security", "Setting"]}
                  handleClickTabItem={handleClickTabItem}
                  activeTab={activeTab.value}
                />
              </div>
              {renderBody()}
            </div>
            <div
              className={`absolute top-0 flex items-center justify-center
                ${
                  userInfo.avatar
                    ? " profile-avatar bg-no-repeat bg-center bg-cover"
                    : ""
                }
              `}
              style={{
                border: "1px solid #E4E4E4",
                backgroundImage: userInfo.avatar
                  ? `url(${showImageUrl(userInfo.avatar, URLAPIAvatar)})`
                  : "inherit",
                width: 128,
                height: 128,
                borderRadius: "50%",
                left: 12,
                backgroundColor: "#F7F7F7",
              }}
              onClick={userInfo.avatar ? removeProfileIcon : undefined}
            >
              {!userInfo.avatar ? <IconNoAvatar /> : null}
            </div>

            <input
              id="container-update-avatar"
              onChange={fileSelectedHandler}
              accept={"image/*"}
              type="file"
              style={{ display: "none" }}
              key={keyInputFile.value || ""}
            />
            <label htmlFor="container-update-avatar">
              <div
                className="absolute cursor-pointer"
                style={{
                  left: 108,
                  top: 96,
                }}
              >
                <IconUpdateAvatar />
              </div>
            </label>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}
export default General
