import {
  STATUS_BUILD,
  ProjectComponentStatus,
  BUILD_FINISHED_STATUSES,
} from "components/Status/types"
import { checkPermissionPage } from "helpers/utils"
import { ProjectComponentDetail } from "pages/project-component/types"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import { useContext } from "react"
import { PermissionProjectBuildPage } from "../contexts/PermissionProjectBuildPage.context"
import NoDataCard from "../molecules/NoDataCard"
import { useSelectComponentInProjectBuild } from "../project-build.hook"
import { MENU_TAB_BUILD } from "../project-build.type"
import ComponentDetailInBuild from "./ComponentDetailInBuild "

interface Props {
  projectBuild: ProjectComponentBuildDetail
  gridCols: string
  onSyncDataComponent: (newItem: ProjectComponentDetail) => (event) => void
  onReShareKeyComponent?: (newItem: ProjectComponentDetail) => (event) => void
}
const ComponentBuildSyncCard = (props: Props) => {
  const { projectBuild, gridCols, onSyncDataComponent, onReShareKeyComponent } =
    props
  const { components } = projectBuild
  const { archiveProject, archiveBuild, viewOnlyShare, status, tabMenu } =
    useContext(PermissionProjectBuildPage)
  const { onSelectComponent } = useSelectComponentInProjectBuild()
  const isDeleted = projectBuild.status === STATUS_BUILD.DELETED
  return (
    <div className="h-full w-full">
      {components.length ? (
        <div
          className={`grid ${gridCols} py-0 md:py-[5px] px-0`}
          style={{
            gridGap: 24,
          }}
        >
          {components.map((el, index) => {
            return (
              <ComponentDetailInBuild
                key={index}
                item={el}
                isHoverCard
                isSync
                onSyncData={onSyncDataComponent}
                onReShareKey={onReShareKeyComponent}
                isDeleted={
                  isDeleted ||
                  el.status === ProjectComponentStatus.DeletedAttachment
                }
                isShowInvitee={
                  BUILD_FINISHED_STATUSES.includes(status as any) &&
                  tabMenu === MENU_TAB_BUILD.INVITEES
                }
                isShowReShare={
                  projectBuild.status !== STATUS_BUILD.COMMITTED ? true : false
                }
                onSelectComponent={onSelectComponent}
                isShowStatus
                isView={
                  checkPermissionPage({
                    project: archiveProject,
                    build: archiveBuild,
                    viewShare: viewOnlyShare,
                  }) || BUILD_FINISHED_STATUSES.includes(status as any)
                }
                canSync={el.can_sync}
              />
            )
          })}
        </div>
      ) : (
        <NoDataCard />
      )}
    </div>
  )
}

export default ComponentBuildSyncCard
