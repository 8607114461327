import ModalCustom from "components/ModalCustom"
import { ReactComponent as IconGoogleAuthen } from "assets/images/icons/google-authen.svg"
import Button from "components/Button/Button"

interface Props {
  onSetUpGoogleAuthenticator: () => void
}
export const WarningEnhanceAuthenticationModal = (props: Props) => {
  const { onSetUpGoogleAuthenticator } = props

  const renderHeader = () => {
    return (
      <div className="flex">
        <IconGoogleAuthen />
        <p className="ml-[8px] text-[16px] text-[#222222] font-semibold">
          Secure your account with 2FA
        </p>
      </div>
    )
  }

  const renderBody = () => {
    return (
      <div className="flex flex-col">
        <p className="text-[14px] text-[#222222] leading-[24px]">
          Two-factor authentication adds extra security by requiring a code at
          login. You must enable at least one method:
        </p>
        <div className="flex flex-col mt-[14px] ml-[8px]">
          <div className="flex items-center">
            <div className="h-[5px] min-w-[5px] bg-[#222222] rounded-full "></div>
            <p className="text-[14px] text-[#222222] leading-[24px] ml-[12px]">
              Google Authenticator
            </p>
          </div>
          <div className="flex items-center">
            <div className="h-[5px] min-w-[5px] bg-[#222222] rounded-full "></div>
            <p className="text-[14px] text-[#222222] leading-[24px] ml-[12px]">
              SMS OTP
            </p>
          </div>
        </div>
        <p className="text-[14px] text-[#222222] leading-[24px] mt-[14px]">
          The Email OTP option is enabled by default.
        </p>
        <div className="mt-[32px]">
          <Button
            widthBtn={"100%"}
            title="Enable 2FA "
            onClick={onSetUpGoogleAuthenticator}
          />
        </div>
      </div>
    )
  }
  return (
    <ModalCustom
      label="Enhance security with two-factor authentication"
      widthModal={400}
      styleHeaderModal={{
        height: "auto",
        paddingTop: 12,
        alignItems: "start",
      }}
      renderHeader={renderHeader()}
      bodyChildren={renderBody()}
    />
  )
}
