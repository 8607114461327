import { STATUS_BUILD } from "components/Status/types"
import { IUseDefaultValueProps, useString } from "helpers/hooks"
import { getProjectBuildMiddleware } from "pages/project-build/api.services"
import {
  ProjectComponentBuildDetail,
  emptyProjectBuildDetail,
} from "pages/project-build/project-build.type"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import BuildDetail from "./organisms/BuildDetail"
import BuildDraft from "./organisms/BuildDraft"
import { cloneDeep } from "lodash"
import { ReactComponent as CollapseIcon } from "assets/images/icons/collapse-icon.svg"
import { ReactComponent as ExpandIcon } from "assets/images/icons/expand-icon.svg"
import { ReactComponent as ArrowLeftIcon } from "assets/images/icons/arrow-left.svg"
import iconNoData from "assets/images/icons/icon-no-data-component.svg"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"

interface Props {
  isArchived: boolean | undefined
  showBuildDetailLayout: IUseDefaultValueProps
  collapse: IUseDefaultValueProps
}
const BuildDetailColumn = (props: Props) => {
  const { isArchived, showBuildDetailLayout, collapse } = props
  const params = useParams<{
    status: string
    idProject: string
    idProjectBuild: string
  }>()
  const [projectBuild, setProjectBuild] = useState<ProjectComponentBuildDetail>(
    emptyProjectBuildDetail
  )
  const updatedAtBuild = useString("")
  const idProjectBuildParams = params?.idProjectBuild || ""
  useEffect(() => {
    getProjectBuild()
  }, [params?.idProjectBuild, params.idProject])
  const getProjectBuild = async () => {
    if (!idProjectBuildParams) {
      setProjectBuild(emptyProjectBuildDetail)
    } else {
      const dataRes = await getProjectBuildMiddleware(idProjectBuildParams)
      setProjectBuild(dataRes)
    }
  }
  const onUpdateDataBuild = (
    updatedData: Partial<ProjectComponentBuildDetail>
  ) => {
    // console.log("newProjectBuild : ", newProjectBuild)
    // console.log("oldProjectBuild : ", projectBuild)

    // const oldProjectBuild = cloneDeep(projectBuild)
    // oldProjectBuild.name = newProjectBuild.name
    // oldProjectBuild.updated_at = newProjectBuild.updated_at
    setProjectBuild({ ...projectBuild, ...updatedData })
    updatedAtBuild.setValue(new Date().toISOString())
  }
  return projectBuild.id ? (
    <div className={`relative w-full`}>
      <div
        className="absolute left-[-1px] bottom-[50%] cursor-pointer hidden md:block"
        onClick={() => {
          collapse.setValue(!collapse.value)
        }}
      >
        {collapse.value ? (
          <CollapseIcon className="collapse-icon" />
        ) : (
          <ExpandIcon className="collapse-icon" />
        )}
      </div>
      {projectBuild.status === STATUS_BUILD.DRAFT ? (
        <BuildDraft
          onUpdateDataEditNameBuild={onUpdateDataBuild}
          projectBuild={projectBuild}
          showBuildDetailLayout={showBuildDetailLayout}
          isArchived={isArchived}
          updatedAtBuild={updatedAtBuild.value}
        />
      ) : (
        <BuildDetail
          onUpdateDataEditNameBuild={onUpdateDataBuild}
          projectBuild={projectBuild}
          showBuildDetailLayout={showBuildDetailLayout}
          isArchived={isArchived}
          updatedAtBuild={updatedAtBuild.value}
          updateProjectBuild={getProjectBuild}
        />
      )}
    </div>
  ) : (
    <div className="flex flex-1 flex-col h-full overflow-hidden">
      <div
        className="flex md:hidden p-6"
        onClick={() => {
          showBuildDetailLayout.setValue(false)
        }}
      >
        <ArrowLeftIcon />
      </div>
      <div className="bg-white flex flex-1 items-center justify-center">
        <div className="m-6">
          <img src={iconNoData} alt="icon" />
        </div>
      </div>
    </div>
  )
}
export default BuildDetailColumn
