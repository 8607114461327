import Button from "components/Button/Button"
import ModalCustom from "components/ModalCustom"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { EncryptionKeys } from "controllers/EncryptionController"
import { encryptionHelper } from "helpers/encryption"
import { toast } from "react-toastify"
import { postGenerateNewRecoveryCodeMiddleware } from "../services/api"
import { MESSENGER_NOTIFICATION } from "constants/messenger"

interface Props {
  onCloseModal: () => void
  handleAfterGenerate: (backupKeyCode: string) => void
}

export const GenerateNewRecoveryCodeModal = (props: Props) => {
  const { onCloseModal, handleAfterGenerate } = props

  const onGenerate = async () => {
    const vaultKey = localStorage.getItem(EncryptionKeys.userVaultKey) || ""
    if (!vaultKey) {
      toast(
        <LabelNotificationPage
          type="error"
          messenger={"Vault key not found!"}
        />
      )
      return
    }
    const backupKeyCode = encryptionHelper.createBackupKey()

    const encryptedBackupKeyCode = encryptionHelper.encrypt(
      backupKeyCode,
      vaultKey
    )
    await postGenerateNewRecoveryCodeMiddleware(encryptedBackupKeyCode)
    toast(
      <LabelNotificationPage
        type="success"
        messenger={MESSENGER_NOTIFICATION.GENERATE_NEW_RECOVERY_CODE_SUCCESS}
      />
    )
    handleAfterGenerate(backupKeyCode)
  }
  return (
    <ModalCustom
      label="Generate new recovery code"
      handleChangeButton={() => onCloseModal}
      bodyChildren={
        <div className="flex flex-col pt-[4px]">
          <div className="flex h-[82px] items-center text-center  mb-[16px]">
            <p className="text-[#222222] text-[14px] font-normal leading-[24px]">
              Generating a new recovery code will permanently deactivate the
              previous one.
            </p>
          </div>
          <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-between">
            <Button
              widthBtn={"100%"}
              title="Cancel"
              colorBtn="white"
              onClick={onCloseModal}
            />
            <Button
              widthBtn={"100%"}
              title={"Generate"}
              colorBtn="yellow"
              onClick={onGenerate}
            />
          </div>
        </div>
      }
    />
  )
}
