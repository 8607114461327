import InputDefault from "components/Input/InputDefault"
import { STATUS_INPUT } from "components/Input/types"
import ModalCustom from "components/ModalCustom"
import { useBoolean, useString } from "helpers/hooks"
import FormInputCard from "pages/auth/molecules/FormInputCard"
import { useEffect, useState } from "react"
import {
  FormVendorRequest,
  ProjectBuildInvitessDetail,
  WorkedVendor,
} from "../project-build.type"
import {
  isValidEmail,
  changeLabelStatusEmail,
  umamiTracking,
} from "helpers/utils"
import {
  postProjectBuildCreateInviteeMiddleware,
  putProjectBuildUpdateInviteeMiddleware,
  getWorkedVendors,
} from "../api.services"
import { STATUS_RESPONSE } from "types"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import ActionFooterModalCard from "components/ModalCustom/ActionFooterModalCard"
import SelectMulti from "components/Select/SelectMulti"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { trim } from "lodash"
import { EVENT } from "constants/events"
import EmailChipInput from "components/Input/EmailChipInput"
import React from "react"

interface Props {
  handleChangeData: (newInvitees?: ProjectBuildInvitessDetail) => void
  onCloseModal: () => void
  defaultRequest: FormVendorRequest
  openModal: boolean
  idProjectBuild: string
}
interface VendorState extends WorkedVendor {
  label: string
  value: string
  custom?: boolean
}
type VendorInputType = "name" | "email"

const FormVendor = (props: Props) => {
  const {
    defaultRequest,
    handleChangeData,
    onCloseModal,
    openModal,
    idProjectBuild,
  } = props
  const disabledAutoComplete = false
  const name = useString("")
  const email = useString("")
  const isLoading = useBoolean(false)
  const [statusInputEmail, setStatusInputEmail] = useState({
    status: STATUS_INPUT.DEFAULT,
    label: "",
  })
  const [workedVendors, setWorkedVendors] = useState<WorkedVendor[]>([])
  const [selectedVendor, setSelectedVendor] = useState<VendorState>({
    name: defaultRequest.invitee.name,
    email: defaultRequest.invitee.email || "",
    label: defaultRequest.invitee.name,
    value: defaultRequest.invitee.email || "",
  })
  const [emailsCC, setEmailsCC] = useState<string[]>([])
  useEffect(() => {
    if (!openModal) {
      return
    }
    name.setValue(defaultRequest.invitee.name)
    email.setValue(defaultRequest.invitee.email)
    setEmailsCC(defaultRequest.cc)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultRequest), openModal])

  useEffect(() => {
    if (!disabledAutoComplete) {
      getWorkedVendors().then((res) => {
        setWorkedVendors(res.data)
      })
    }
  }, [])

  useEffect(() => {
    name.setValue(selectedVendor.name)
    email.setValue(selectedVendor.email)
  }, [JSON.stringify(selectedVendor)])

  const getVendorState = (key: VendorInputType) => {
    const data = workedVendors.map((item) => {
      return {
        ...item,
        label: `${item.name} - ${item.email}`,
        value: key === "email" ? item.email : item.name,
      }
    })
    if (key === "email" && selectedVendor.email) {
      return [
        ...data,
        {
          ...selectedVendor,
          label: selectedVendor.email,
          value: selectedVendor.email,
        },
      ]
    }
    if (key === "name" && selectedVendor.name) {
      return [
        ...data,
        {
          ...selectedVendor,
          label: selectedVendor.name,
          value: selectedVendor.name,
        },
      ]
    }
    return data
  }

  const handleChangeInput = (key: VendorInputType) => (event) => {
    if (key === "name") {
      name.setValue(event.target.value)
      return
    }
    email.setValue(event.target.value)
    setStatusInputEmail(changeLabelStatusEmail(event.target.value))
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButton()) {
        return
      }

      onSubmit()
    }
  }

  const handleDisableButton = () => {
    return false
  }
  const onSubmit = () => {
    if (!idProjectBuild) {
      return
    }
    if (email.value && !isValidEmail(trim(email.value))) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.VALIDATE.INVALID_EMAIL}
          type="error"
        />
      )
      return
    }

    isLoading.setValue(true)
    const dataRequest = {
      invitee: {
        name: trim(name.value),
        email: trim(email.value),
      },
      cc: emailsCC,
    }
    if (defaultRequest.invitee.email && !dataRequest.invitee.email) {
      umamiTracking(EVENT.INVITEE.REMOVE)
    }
    if (defaultRequest.id) {
      putProjectBuildUpdateInviteeMiddleware(
        defaultRequest.id,
        dataRequest,
        onUpdateDataWhenSubmit
      )
      return
    }
    postProjectBuildCreateInviteeMiddleware(
      idProjectBuild,
      dataRequest,
      onUpdateDataWhenSubmit
    )
  }

  const onUpdateDataWhenSubmit = (type: STATUS_RESPONSE, messenger: string) => {
    isLoading.setValue(false)
    toast(<LabelNotificationPage messenger={messenger} type={type} />)
    onCloseModal()
    if (type === STATUS_RESPONSE.SUCCESS) {
      handleChangeData()
    }
  }

  const handleInputVendorChange =
    (key: VendorInputType) => (inputValue: string) => {
      setSelectedVendor({
        ...selectedVendor,
        [key]: inputValue,
        value: inputValue,
      })
    }
  const renderEditInviteeTab = () => (
    <div className="flex flex-col">
      <FormInputCard
        title="Contact name"
        required
        fontWeightText="font-semibold"
        disabled={defaultRequest.id ? true : false}
      >
        {!disabledAutoComplete ? (
          <SelectMulti
            options={getVendorState("name")}
            selectedOption={{
              label: selectedVendor.name,
              value: selectedVendor.name,
            }}
            handleChange={(changedData) => {
              setSelectedVendor(changedData)
            }}
            isMulti={false}
            noOptionsMessage
            onBlur={(e: any) => {
              handleInputVendorChange("name")(e.target.value)
            }}
            handleInputChange={(value, action) => {
              if (action?.action === "input-change") {
                handleInputVendorChange("name")(value)
              }
            }}
            inputValue={selectedVendor.name}
          />
        ) : (
          <InputDefault
            value={name.value}
            onChange={handleChangeInput("name")}
            onKeyPress={onKeyPress}
          />
        )}
      </FormInputCard>

      <FormInputCard
        title="Email address"
        fontWeightText="font-semibold"
        disabled={defaultRequest.id ? true : false}
      >
        {!disabledAutoComplete && !defaultRequest.is_sent ? (
          <SelectMulti
            options={getVendorState("email")}
            selectedOption={{
              label: selectedVendor.email,
              value: selectedVendor.email,
            }}
            handleChange={(changedData) => {
              setSelectedVendor(changedData)
            }}
            isMulti={false}
            noOptionsMessage
            onBlur={(e: any) => {
              handleInputVendorChange("email")(e.target.value)
            }}
            handleInputChange={(value, action) => {
              if (action?.action === "input-change") {
                handleInputVendorChange("email")(value)
              }
            }}
            inputValue={selectedVendor.email}
          />
        ) : (
          <InputDefault
            value={email.value}
            status={statusInputEmail.status}
            labelStatus={statusInputEmail.label}
            disabled={true}
          />
        )}
      </FormInputCard>
      <FormInputCard title="CC" fontWeightText="font-semibold">
        <p className="font-light italic text-xs mb-2 text-black">
          Type or paste email addresses and press `Enter`...
        </p>
        <EmailChipInput emails={emailsCC} setEmails={setEmailsCC} />
      </FormInputCard>

      <ActionFooterModalCard
        onCloseModal={onCloseModal}
        onSubmit={onSubmit}
        handleDisableButton={handleDisableButton()}
        titleButton={defaultRequest.id ? "Save" : "Add"}
        styleRoot={{
          marginTop: 20,
        }}
      />
    </div>
  )

  return (
    <ModalCustom
      label={`${defaultRequest.id ? "Edit Invitee" : "Add a invitee"}`}
      handleChangeButton={() => onCloseModal}
      bodyChildren={renderEditInviteeTab()}
    />
  )
}
export default FormVendor
