import { TippyProps } from "@tippyjs/react"
import { IUseDefaultValueProps } from "helpers/hooks"

export interface PageLayoutAuthProps {
  children: React.ReactNode
  showPrivacy?: boolean
}

export interface VerifySuccessProps {
  children: React.ReactNode
  showLabel?: string
  labelContent?: string
}

export interface FormInputCardProps extends Omit<TippyProps, "children"> {
  children: React.ReactNode
  title: string
  label?: string
  isTippy?: boolean
  isHover?: boolean
  customClass?: string
  required?: boolean
  fontWeightText?: string
  variantText?: "h5" | "h6"
  showToolTip?: IUseDefaultValueProps
}

export interface HeaderProps {
  title: string
}

export interface LabelChangeRouterProps {
  title: string
  label: string
  path: string
}

export interface RememberMeCardProps {
  title: string
  checked: boolean
  setChecked: (checked: boolean) => void
}

export interface SignUpRequest {
  fullname: string
  email: string
  backupKeyCode: string
  company_name?: string
  company_role?: {
    id: string
    custom_role?: string
  }
  password: string
  confirmedPassword?: string
  // encryptedVaultKey: string
  // encryptedBackUpkey: string
  captcha?: string
}
export interface InviteSentSignUpRequest {
  fullname: string
  email: string
  password: string
  confirmedPassword: string
  captcha: string
  backupKeyCode: string
}

export const defaultSignUpRequest: SignUpRequest = {
  fullname: "",
  email: "",
  company_name: "",
  company_role: {
    id: "",
    custom_role: "",
  },
  password: "",
  backupKeyCode: "",
  // encryptedVaultKey: "",
  // encryptedBackUpkey: "",
}

export interface LoginRequest {
  email: string
  password: string
  captcha?: string
}

export const defaultLoginRequest: LoginRequest = {
  email: "",
  password: "",
}

export interface ForgetPasswordRequest {
  email: string
  captcha?: string
}
export const defaultForgetPasswordRequest: ForgetPasswordRequest = {
  email: "",
}

export interface PasswordResetRequest {
  password: string
  confirmedPassword: string
  mfaCode?: string
  captcha?: string
  resetPasswordToken?: string
  encryptedVaultKey?: string
  masterKey?: string
}
export const defaultPasswordResetRequest: PasswordResetRequest = {
  password: "",
  confirmedPassword: "",
}

export enum TYPE_RESEND_EMAIL {
  VERIFICATION = "verification",
  FORGOT_PASSWORD = "forgot_password",
}

export interface UserInfo {
  id: string
  fullname: string
  company_name: string
  company_role_id: string
  company_role_custom: string
  address?: string
  email: string
  role_id: string
  status: number
  avatar: string
  is_verified: number
  phone_number: string
  has_new_shared_project: number
  has_agreed_latest_document: boolean
  enable_2fa?: boolean
  timezone?: string
  has_seen_release_notes?: boolean
  recived_email_from_my_own_messages?: boolean
  is_encrypted?: boolean
  isDoneSyncEncrypted?: boolean
  mfaMethods?: MFAMethod[]
  is_phone_verified: number
}

export interface ChangePasswordRequest {
  currentPassword: string
  newPassword: string
  confirmedNewPassword: string
}

export const defaultChangePasswordRequest: ChangePasswordRequest = {
  confirmedNewPassword: "",
  currentPassword: "",
  newPassword: "",
}

export interface ChangePasswordCardProps {
  handleUpdateNotification: (mes: string) => void
}

export interface InformationUserCardProps {
  handleUpdateNotification: (mes: string) => void
}
export interface InformationUserRequest {
  fullname: string
  company_name?: string
  avatar?: string
  address?: string
  company_role?: {
    id: string
    custom_role?: string
  }
}

export const defaultInformationUserRequest: InformationUserRequest = {
  fullname: "",
  company_name: "",
  address: "",
}

export const emptyUserInfo: UserInfo = {
  address: "",
  avatar: "",
  company_name: "",
  company_role_custom: "",
  company_role_id: "",
  email: "",
  fullname: "",
  id: "",
  is_verified: 0,
  phone_number: "",
  role_id: "",
  status: 0,
  has_new_shared_project: 0,
  has_agreed_latest_document: true,
  has_seen_release_notes: false,
  recived_email_from_my_own_messages: false,
  is_encrypted: false,
  is_phone_verified: 0,
}

export interface PrivacyPartDetail {
  id: number
  question: string
  answer: string
  created_at: string
  updated_at: string
}
export interface PrivacyDetail {
  updated_at: string
  parts: PrivacyPartDetail[]
}

// Shipping address
export interface InputValueAddNewAddressProp {
  fullname: string
  phone_number: string
  address: string
  country: {
    label: string
    value: string
  }
  state: string
  city: string
  postal_code: string
  vat: string
}

export const DefaultInputValueAddNewAddress = {
  fullname: "",
  phone_number: "",
  address: "",
  country: {
    label: "",
    value: "",
  },
  state: "",
  city: "",
  postal_code: "",
  vat: "",
}

export interface CountryDataProp {
  country: string
  countryCode: string
}

export type KeyProp =
  | "fullname"
  | "company_name"
  | "address"
  | "country"
  | "state"
  | "city"
  | "postal_code"
  | "vat"

export interface AddNewAddressFormProps {
  isDisabelButton?: boolean
  openModal: (value: boolean) => void
  handleChangePhoneNumber: (value) => void
  handleChangeInput: (key: KeyProp) => (event) => void
  inputValue: InputValueAddNewAddressProp
  isLoading: {
    value: boolean
    setValue: (value: boolean) => void
  }
  handleSubmit: () => void
}
export interface LoginResponse {
  message: string
  statusCode: number
  encryptedVaultkey: string
  isDoneSyncEncrypted?: any
  projectKeys?: any
  token: string
  type?: string
}

export interface EnableEncryptionRequest {
  password: string
  passwordHash: string
  encryptedVaultkey: string
  encryptedBackUpkey: string
  encryptedPrivateKey: string
  publicKey: string
  encryptedKMSBackupKey: string
  encryptedBackupKeyCode: string
}
export interface CompanyRole {
  name: string
  id: string
}

export interface IsEnabledMFAResponse {
  data: {
    isEnabled: boolean
    methods: MFAMethod[]
    maskPhone: string
    maskEmail: string
  }
  statusCode: number
}

export enum MFAMethod {
  authenticatorApp,
  email,
  sms,
}
