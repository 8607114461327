import { useBoolean, useNumber } from "helpers/hooks"
import { useRef } from "react"

export const useTimeOutResend = (defaultTime = 30) => {
  const disableResend = useBoolean(false)
  const timeOutResend = useNumber(defaultTime)
  const timeResendInterval = useRef<any>(null)
  const countTimeResend = (time: number) => {
    if (timeResendInterval.current) {
      clearInterval(timeResendInterval.current)
    }
    let timeString = time
    timeResendInterval.current = setInterval(() => {
      const currentTime = timeString - 1
      if (currentTime === 0) {
        disableResend.setValue(false)
        clearInterval(timeResendInterval.current)
        return
      }
      timeString = currentTime
      timeOutResend.setValue(timeString)
    }, 1000)
  }
  return { disableResend, timeOutResend, countTimeResend }
}
