import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { getPrivateKeyByUserId, readLinkS3AndCreateFile } from "helpers/utils"
import { cloneDeep, includes, isUndefined, pick } from "lodash"
import {
  InviteeBuilComponentDetail,
  ProjectBuildInvitessDetail,
  SendComponentInviteeRequest,
} from "pages/project-build/project-build.type"
import {
  getComponentSubBOMMiddleware,
  getPCBMiddleware,
  getPCBSubBOMMiddleware,
  getProjectComponentBOMMiddleware,
  getProjectComponentConversationBOMMiddleware,
  getProjectComponentOtherMiddleware,
  syncNewVersionComponentInviteeMiddleWare,
} from "pages/project-component-detail/services"
import { PROJECT_COMPONENT_TYPE } from "pages/project-component/project-component.constant"
import forge from "node-forge"
import { encryptionHelper } from "helpers/encryption"
import {
  getProjectBuildInviteeMiddleware,
  postUploadFileInviteeMiddleware,
} from "pages/project-build/api.services"
import { MessageBackupType } from "components/FormBackup/type"
import { useState } from "react"
import { useBoolean } from "helpers/hooks"
import { activityLogHelper, ActivityLogType } from "helpers/activity_log"
import { createEncryptionKeysMiddleware } from "pages/projects/services/api"
import { EncryptionKeyInput, KeyEncryptionType } from "types"
import { generateDataKeyPairMiddleware } from "pages/auth/services/api"

export const useAddComponentInInvitee = () => {
  const [messageBackups, setMessageBackups] = useState<
    { content: string; type: MessageBackupType }[]
  >([])

  const [currentMessageBackup, setCurrentMessageBackup] =
    useState<MessageBackupType>(MessageBackupType.RETRIEVE_BACKUP_VERSION)
  const errorMessageBackup = useBoolean(false)
  const isLoadingBackupInvitee = useBoolean(false)

  const createNewComponentHistoryById = async (
    component: InviteeBuilComponentDetail,
    conversationId: string
  ) => {
    const userVaultKey = localStorage.getItem(
      EncryptionKeys.userVaultKey
    ) as string

    const localUserId = localStorage.getItem("user_id") as string

    const componentType = component.type

    let dataComponentRequest: SendComponentInviteeRequest | null = {
      commit: "",
      componentType,
    }
    const fileHashKeys: any = []
    const projectComponentHistoryId =
      component.project_component_history_share_id

    if (projectComponentHistoryId) {
      try {
        const privateKey: any = await getPrivateKeyByUserId()

        const decryptedShareKey = privateKey.decrypt(
          component.shareKey || "",
          "RSA-OAEP"
        )

        if (componentType === PROJECT_COMPONENT_TYPE.PCB) {
          await sendComponentInviteePCB(
            projectComponentHistoryId,
            component.history_from || "",
            conversationId,
            decryptedShareKey,
            userVaultKey,
            dataComponentRequest,
            fileHashKeys,
            localUserId
          )
          if (dataComponentRequest.boomFile) {
            const dataPCBSubBom = await getPCBSubBOMMiddleware(
              projectComponentHistoryId,
              conversationId,
              decryptedShareKey
            )
            if (
              !isUndefined(dataPCBSubBom.mouser_data) &&
              dataPCBSubBom.mouser_data.length
            ) {
              const encryptedMouser = await encryptionController().encrypt(
                JSON.stringify(dataPCBSubBom.mouser_data),
                {
                  dataType: "string",
                  type: "component-shared",
                  encryptionKey: userVaultKey,
                }
              )
              dataComponentRequest.mouser_data = encryptedMouser
            }
            if (
              !isUndefined(dataPCBSubBom.additional_json) &&
              dataPCBSubBom.additional_json.length
            ) {
              const newData: any = await Promise.all(
                dataPCBSubBom.additional_json.map(async (item) => {
                  const encryptedValues = await encryptionController().encrypt(
                    JSON.stringify(item.values),
                    {
                      dataType: "string",
                      type: "component-shared",
                      encryptionKey: userVaultKey,
                    }
                  )
                  return {
                    ...item,
                    values: encryptedValues,
                  }
                })
              )
              dataComponentRequest.additionalJson = newData
            }
          }
        }
        if (
          includes(
            [
              PROJECT_COMPONENT_TYPE.SOFTWARE,
              PROJECT_COMPONENT_TYPE.FIRMWARE,
              PROJECT_COMPONENT_TYPE.MECHANICAL,
              PROJECT_COMPONENT_TYPE.MISCELLANEOUS,
            ],
            component.type
          )
        ) {
          let type = "software"
          switch (component.type) {
            case PROJECT_COMPONENT_TYPE.MECHANICAL:
              type = "mechanical"
              break
            case PROJECT_COMPONENT_TYPE.FIRMWARE:
              type = "firmware"
              break
            case PROJECT_COMPONENT_TYPE.MISCELLANEOUS:
              type = "miscellaneous"
              break
          }
          const dataResOther = await sendComponentInviteeOther(
            projectComponentHistoryId,
            dataComponentRequest,
            type,
            fileHashKeys,
            decryptedShareKey,
            component.history_from || "",
            userVaultKey,
            localUserId,
            conversationId
          )

          if (
            component.type === PROJECT_COMPONENT_TYPE.MECHANICAL &&
            dataResOther?.bom_id
          ) {
            const dataBoom = await getComponentSubBOMMiddleware(
              projectComponentHistoryId,
              type,
              conversationId,
              decryptedShareKey
            )

            if (dataBoom.file) {
              const bomFileMechanical = await readLinkS3AndCreateFile(
                dataBoom.file,
                projectComponentHistoryId,
                decryptedShareKey,
                true,
                component.history_from || "",
                userVaultKey,
                localUserId,
                conversationId
              )
              if (bomFileMechanical) {
                dataComponentRequest.boomFile = {
                  file_name: bomFileMechanical.file_name,
                  file_hash: bomFileMechanical.file_hash,
                }
                fileHashKeys.push(bomFileMechanical)
              }
            }
            if (
              !isUndefined(dataBoom.mouser_data) &&
              dataBoom.mouser_data.length
            ) {
              const encryptedMouser = await encryptionController().encrypt(
                JSON.stringify(dataBoom.mouser_data),
                {
                  dataType: "string",
                  type: "component-shared",
                  encryptionKey: userVaultKey,
                }
              )
              dataComponentRequest.mouser_data = encryptedMouser
            }
            if (
              !isUndefined(dataBoom.additional_json) &&
              dataBoom.additional_json.length
            ) {
              const newData: any = await Promise.all(
                dataBoom.additional_json.map(async (item) => {
                  const encryptedValues = await encryptionController().encrypt(
                    JSON.stringify(item.values),
                    {
                      dataType: "string",
                      type: "component-shared",
                      encryptionKey: userVaultKey,
                    }
                  )
                  return {
                    ...item,
                    values: encryptedValues,
                  }
                })
              )
              dataComponentRequest.additionalJson = newData
            }
          }
        }

        if (component.type === PROJECT_COMPONENT_TYPE.BOM) {
          const dataBOM = await getProjectComponentConversationBOMMiddleware(
            projectComponentHistoryId,
            conversationId,
            decryptedShareKey
          )

          if (dataBOM.file) {
            const bomFile = await readLinkS3AndCreateFile(
              dataBOM.file,
              projectComponentHistoryId,
              decryptedShareKey,
              true,
              component.history_from || "",
              userVaultKey,
              localUserId,
              conversationId
            )
            if (bomFile) {
              dataComponentRequest.boomFile = {
                file_name: bomFile.file_name,
                file_hash: bomFile.file_hash,
              }
              fileHashKeys.push(bomFile)
            }
          }
          if (!isUndefined(dataBOM.mouser_data) && dataBOM.mouser_data.length) {
            const encryptedMouser = await encryptionController().encrypt(
              JSON.stringify(dataBOM.mouser_data),
              {
                dataType: "string",
                type: "component-shared",
                encryptionKey: userVaultKey,
              }
            )
            dataComponentRequest.mouser_data = encryptedMouser
          }
          if (
            !isUndefined(dataBOM.additional_json) &&
            dataBOM.additional_json.length
          ) {
            const newData: any = await Promise.all(
              dataBOM.additional_json.map(async (item) => {
                const encryptedValues = await encryptionController().encrypt(
                  JSON.stringify(item.values),
                  {
                    dataType: "string",
                    type: "component-shared",
                    encryptionKey: userVaultKey,
                  }
                )
                return {
                  ...item,
                  values: encryptedValues,
                }
              })
            )
            dataComponentRequest.additionalJson = newData
          }
          if (dataBOM.projectComponentHistoryCommit) {
            const commit = dataBOM.projectComponentHistoryCommit
              ? await encryptionController().decrypt(
                  dataBOM.projectComponentHistoryCommit,
                  {
                    dataType: "string",
                    type: "component-shared",
                    encryptionKey: decryptedShareKey,
                  }
                )
              : ""
            const encryptCommit = commit
              ? await encryptionController().encrypt(commit, {
                  dataType: "string",
                  type: "component-shared",
                  encryptionKey: userVaultKey,
                })
              : ""
            dataComponentRequest.commit = encryptCommit
          }
        }
      } catch (error) {
        dataComponentRequest = null
      }
    } else {
      dataComponentRequest = null
    }

    return {
      dataComponentRequest,
      fileHashKeys,
    }
  }
  const sendComponentInviteePCB = async (
    projectComponentHistoryId: string,
    historyFromId: string,
    conversationId: string,
    sharedKey: string,
    userVaultKey: string,
    dataComponentRequest: SendComponentInviteeRequest,
    fileHashKeys: any[],
    userId: string
  ) => {
    const dataPCBRes = await getPCBMiddleware(
      projectComponentHistoryId,
      conversationId
    )

    if (dataPCBRes?.assembly_file) {
      const assemblyFile = await readLinkS3AndCreateFile(
        dataPCBRes?.assembly_file,
        projectComponentHistoryId,
        sharedKey,
        true,
        historyFromId,
        userVaultKey,
        userId,
        conversationId
      )
      if (assemblyFile) {
        dataComponentRequest.assemblyFile = {
          file_name: assemblyFile.file_name,
          file_hash: assemblyFile.file_hash,
        }
        fileHashKeys.push(assemblyFile)
      }
    }
    if (dataPCBRes?.bom_file) {
      const bomFile = await readLinkS3AndCreateFile(
        dataPCBRes?.bom_file,
        projectComponentHistoryId,
        sharedKey,
        true,
        historyFromId,
        userVaultKey,
        userId,
        conversationId
      )
      if (bomFile) {
        dataComponentRequest.boomFile = {
          file_name: bomFile.file_name,
          file_hash: bomFile.file_hash,
        }
        fileHashKeys.push(bomFile)
      }
    }
    if (dataPCBRes?.stackup_file) {
      const stackupFile = await readLinkS3AndCreateFile(
        dataPCBRes?.stackup_file,
        projectComponentHistoryId,
        sharedKey,
        true,
        historyFromId,
        userVaultKey,
        userId,
        conversationId
      )
      if (stackupFile) {
        dataComponentRequest.stackupFile = {
          file_name: stackupFile.file_name,
          file_hash: stackupFile.file_hash,
        }
        fileHashKeys.push(stackupFile)
      }
    }
    if (dataPCBRes?.zip_file) {
      const gerberFile = await readLinkS3AndCreateFile(
        dataPCBRes?.zip_file,
        projectComponentHistoryId,
        sharedKey,
        true,
        historyFromId,
        userVaultKey,
        userId,
        conversationId
      )
      if (gerberFile) {
        dataComponentRequest.gerberFile = {
          file_name: gerberFile.file_name,
          file_hash: gerberFile.file_hash,
        }
        fileHashKeys.push(gerberFile)
      }
      if (dataPCBRes?.files.length) {
        const attachments = await Promise.all(
          dataPCBRes?.files.map((attachment) =>
            readLinkS3AndCreateFile(
              attachment.file,
              projectComponentHistoryId,
              sharedKey,
              true,
              historyFromId,
              userVaultKey,
              userId,
              conversationId
            )
          )
        )
        dataComponentRequest.files = attachments
          .filter((el) => el)
          .map((attachment) => {
            fileHashKeys.push(attachment)
            return {
              file_name: attachment.file_name,
              file_hash: attachment.file_hash,
            }
          })
      }
      if (dataPCBRes?.tempLayers.length) {
        const layers = await Promise.all(
          dataPCBRes?.tempLayers.map(async (layer) => {
            const fileHash = await readLinkS3AndCreateFile(
              layer.file,
              projectComponentHistoryId,
              sharedKey,
              false,
              historyFromId,
              userVaultKey,
              userId,
              conversationId
            )
            fileHashKeys.push(fileHash)
            return {
              ...layer,
              file_name_hash: fileHash.file_name,
            }
          })
        )
        dataComponentRequest.pcbLayers = layers.map((layer) => {
          return {
            ...pick(layer, [
              "color",
              "custom_sort",
              "side",
              "type",
              "file_name",
              "sequence",
              "sort_index",
              "file_name_hash",
            ]),
          }
        }) as any
      }
      if (dataPCBRes.projectComponentHistoryCommit) {
        const commit = await encryptionController().decrypt(
          dataPCBRes.projectComponentHistoryCommit,
          {
            dataType: "string",
            type: "component-shared",
            encryptionKey: sharedKey,
          }
        )
        const encryptCommit = await encryptionController().encrypt(commit, {
          dataType: "string",
          type: "component-shared",
          encryptionKey: userVaultKey,
        })
        dataComponentRequest.commit = encryptCommit
      }
      if (dataPCBRes.specifications.length) {
        const specifications: {
          specification_part_id: string
          value: string
        }[] = []
        let specificationsFiles: any[] = []
        dataPCBRes.specifications[0].parts.forEach(async (part) => {
          if (part.files && part.files.length) {
            specificationsFiles = specificationsFiles.concat(
              part.files.map((el) => {
                return {
                  ...el,
                  specification_part_id: part.id,
                }
              })
            )
          }

          specifications.push({
            specification_part_id: part.id,
            value: part.value,
          })
        })

        if (specifications.length) {
          dataComponentRequest.specifications = specifications
        }
        if (specificationsFiles.length) {
          const specificationsHashFiles = await Promise.all(
            specificationsFiles.map(async (elFile) => {
              const fileHash = await readLinkS3AndCreateFile(
                elFile.file,
                projectComponentHistoryId,
                sharedKey,
                true,
                historyFromId,
                userVaultKey,
                userId,
                conversationId
              )
              fileHashKeys.push(fileHash)
              return {
                file_name: fileHash.file_name,
                file_hash: fileHash.file_hash,
                specification_part_id: elFile.specification_part_id,
              }
            })
          )
          dataComponentRequest.specificationFiles = specificationsHashFiles
        }
      }
      if (dataPCBRes.specification_html_file) {
        dataComponentRequest.specification_html_files =
          dataPCBRes.specification_html_file
      }
    }
  }

  const sendComponentInviteeOther = async (
    projectComponentHistoryId: string,
    dataComponentRequest: SendComponentInviteeRequest,
    type: string,
    fileHashKeys: any[],
    sharedKey: string,
    historyFromId: string,
    userVaultKey: string,
    userId: string,
    conversationId: string
  ) => {
    const dataRes = await getProjectComponentOtherMiddleware(
      projectComponentHistoryId,
      type,
      conversationId,
      sharedKey
    )
    if (dataRes?.files.length) {
      const attachments = await Promise.all(
        dataRes?.files.map((attachment) =>
          readLinkS3AndCreateFile(
            attachment.file,
            projectComponentHistoryId,
            sharedKey,
            true,
            historyFromId,
            userVaultKey,
            userId,
            conversationId
          )
        )
      )
      dataComponentRequest.files = attachments
        .filter((el) => el)
        .map((attachment) => {
          fileHashKeys.push(attachment)
          return {
            file_name: attachment.file_name,
            file_hash: attachment.file_hash,
          }
        })
    }
    if (dataRes.projectComponentHistoryCommit) {
      const commit = await encryptionController().decrypt(
        dataRes.projectComponentHistoryCommit,
        {
          dataType: "string",
          type: "component-shared",
          encryptionKey: sharedKey,
        }
      )
      const encryptCommit = await encryptionController().encrypt(commit, {
        dataType: "string",
        type: "component-shared",
        encryptionKey: userVaultKey,
      })
      dataComponentRequest.commit = encryptCommit
    }
    if (dataRes.description) {
      const encryptDescription = await encryptionController().encrypt(
        dataRes.description,
        {
          dataType: "string",
          type: "component-shared",
          encryptionKey: userVaultKey,
        }
      )
      dataComponentRequest.description = encryptDescription
    }
    if (dataRes.three_d_file) {
      const threeDFile = await readLinkS3AndCreateFile(
        dataRes.three_d_file,
        projectComponentHistoryId,
        sharedKey,
        true,
        historyFromId,
        userVaultKey,
        userId,
        conversationId
      )
      if (threeDFile) {
        dataComponentRequest.threeDFile = {
          file_name: threeDFile.file_name,
          file_hash: threeDFile.file_hash,
        }
        fileHashKeys.push(threeDFile)
      }
    }
    return dataRes
  }

  const createNewComponentHistoryByOwner = async (
    projectId: string,
    componentType: number,
    projectComponentHistoryId: string
  ) => {
    const dataComponentRequest: SendComponentInviteeRequest = {
      commit: "",
      componentType,
    }

    const fileHashKeys: any = []

    const projectKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
    )
    const projectKey = projectKeys[projectId]
    const sharedKey = await encryptionHelper.createRandomKey()
    const encryptSharedKey = await encryptionController().encrypt(sharedKey, {
      dataType: "string",
      type: "component-shared",
      encryptionKey: projectKey,
    })
    dataComponentRequest.encryptSharedKey = encryptSharedKey
    dataComponentRequest.sharedKey = sharedKey

    if (componentType === PROJECT_COMPONENT_TYPE.PCB) {
      await insertComponentInviteePCB(
        projectComponentHistoryId,
        dataComponentRequest,
        fileHashKeys,
        sharedKey
      )
      if (dataComponentRequest.boomFile) {
        const dataPCBSubBom = await getPCBSubBOMMiddleware(
          projectComponentHistoryId
        )
        if (
          !isUndefined(dataPCBSubBom.mouser_data) &&
          dataPCBSubBom.mouser_data.length
        ) {
          const encryptedMouser = await encryptionController().encrypt(
            JSON.stringify(dataPCBSubBom.mouser_data),
            {
              dataType: "string",
              type: "component-shared",
              encryptionKey: sharedKey,
            }
          )
          dataComponentRequest.mouser_data = encryptedMouser
        }
        if (
          !isUndefined(dataPCBSubBom.additional_json) &&
          dataPCBSubBom.additional_json.length
        ) {
          const newData: any = await Promise.all(
            dataPCBSubBom.additional_json.map(async (item) => {
              const encryptedValues = await encryptionController().encrypt(
                JSON.stringify(item.values),
                {
                  dataType: "string",
                  type: "component-shared",
                  encryptionKey: sharedKey,
                }
              )
              return {
                ...item,
                values: encryptedValues,
              }
            })
          )
          dataComponentRequest.additionalJson = newData
        }
      }
    }
    if (
      includes(
        [
          PROJECT_COMPONENT_TYPE.SOFTWARE,
          PROJECT_COMPONENT_TYPE.FIRMWARE,
          PROJECT_COMPONENT_TYPE.MECHANICAL,
          PROJECT_COMPONENT_TYPE.MISCELLANEOUS,
        ],
        componentType
      )
    ) {
      let type = "software"
      switch (componentType) {
        case PROJECT_COMPONENT_TYPE.MECHANICAL:
          type = "mechanical"
          break
        case PROJECT_COMPONENT_TYPE.FIRMWARE:
          type = "firmware"
          break
        case PROJECT_COMPONENT_TYPE.MISCELLANEOUS:
          type = "miscellaneous"
          break
      }
      const dataResOther = await insertComponentInviteeOther(
        projectComponentHistoryId,
        dataComponentRequest,
        type,
        fileHashKeys,
        sharedKey
      )
      if (
        componentType === PROJECT_COMPONENT_TYPE.MECHANICAL &&
        dataResOther.bom_id
      ) {
        const dataBoom = await getComponentSubBOMMiddleware(
          projectComponentHistoryId,
          type
        )

        if (dataBoom.file) {
          const bomFileMechanical = await readLinkS3AndCreateFile(
            dataBoom.file,
            projectComponentHistoryId,
            sharedKey
          )
          if (bomFileMechanical) {
            dataComponentRequest.boomFile = {
              file_name: bomFileMechanical.file_name,
              file_hash: bomFileMechanical.file_hash,
            }
            fileHashKeys.push(bomFileMechanical)
          }
        }
        if (!isUndefined(dataBoom.mouser_data) && dataBoom.mouser_data.length) {
          const encryptedMouser = await encryptionController().encrypt(
            JSON.stringify(dataBoom.mouser_data),
            {
              dataType: "string",
              type: "component-shared",
              encryptionKey: sharedKey,
            }
          )
          dataComponentRequest.mouser_data = encryptedMouser
        }
        if (
          !isUndefined(dataBoom.additional_json) &&
          dataBoom.additional_json.length
        ) {
          const newData: any = await Promise.all(
            dataBoom.additional_json.map(async (item) => {
              const encryptedValues = await encryptionController().encrypt(
                JSON.stringify(item.values),
                {
                  dataType: "string",
                  type: "component-shared",
                  encryptionKey: sharedKey,
                }
              )
              return {
                ...item,
                values: encryptedValues,
              }
            })
          )
          dataComponentRequest.additionalJson = newData
        }
      }
    }

    if (componentType === PROJECT_COMPONENT_TYPE.BOM) {
      const dataBOM = await getProjectComponentBOMMiddleware(
        projectComponentHistoryId
      )

      if (dataBOM.file) {
        const bomFile = await readLinkS3AndCreateFile(
          dataBOM.file,
          projectComponentHistoryId,
          sharedKey
        )
        if (bomFile) {
          dataComponentRequest.boomFile = {
            file_name: bomFile.file_name,
            file_hash: bomFile.file_hash,
          }
          fileHashKeys.push(bomFile)
        }
      }
      if (!isUndefined(dataBOM.mouser_data) && dataBOM.mouser_data.length) {
        const encryptedMouser = await encryptionController().encrypt(
          JSON.stringify(dataBOM.mouser_data),
          {
            dataType: "string",
            type: "component-shared",
            encryptionKey: sharedKey,
          }
        )
        dataComponentRequest.mouser_data = encryptedMouser
      }
      if (
        !isUndefined(dataBOM.additional_json) &&
        dataBOM.additional_json.length
      ) {
        const newData: any = await Promise.all(
          dataBOM.additional_json.map(async (item) => {
            const encryptedValues = await encryptionController().encrypt(
              JSON.stringify(item.values),
              {
                dataType: "string",
                type: "component-shared",
                encryptionKey: sharedKey,
              }
            )
            return {
              ...item,
              values: encryptedValues,
            }
          })
        )
        dataComponentRequest.additionalJson = newData
      }
      if (dataBOM.projectComponentHistoryCommit) {
        const commit = await encryptionController().decrypt(
          dataBOM.projectComponentHistoryCommit,
          {
            dataType: "string",
            type: "project",
          }
        )
        const encryptCommit = await encryptionController().encrypt(commit, {
          dataType: "string",
          type: "component-shared",
          encryptionKey: sharedKey,
        })
        dataComponentRequest.commit = encryptCommit
      }
    }
    return {
      dataComponentRequest,
      sharedKey,
      fileHashKeys,
    }
  }
  const insertComponentInviteePCB = async (
    projectComponentHistoryId: string,
    dataComponentRequest: SendComponentInviteeRequest,
    fileHashKeys: any[],
    sharedKey: string
  ) => {
    const dataPCBRes = await getPCBMiddleware(projectComponentHistoryId)
    if (dataPCBRes?.assembly_file) {
      const assemblyFile = await readLinkS3AndCreateFile(
        dataPCBRes?.assembly_file,
        projectComponentHistoryId,
        sharedKey
      )
      if (assemblyFile) {
        dataComponentRequest.assemblyFile = {
          file_name: assemblyFile.file_name,
          file_hash: assemblyFile.file_hash,
        }
        fileHashKeys.push(assemblyFile)
      }
    }
    if (dataPCBRes?.bom_file) {
      const bomFile = await readLinkS3AndCreateFile(
        dataPCBRes?.bom_file,
        projectComponentHistoryId,
        sharedKey
      )
      if (bomFile) {
        dataComponentRequest.boomFile = {
          file_name: bomFile.file_name,
          file_hash: bomFile.file_hash,
        }
        fileHashKeys.push(bomFile)
      }
    }
    if (dataPCBRes?.stackup_file) {
      const stackupFile = await readLinkS3AndCreateFile(
        dataPCBRes?.stackup_file,
        projectComponentHistoryId,
        sharedKey
      )
      if (stackupFile) {
        dataComponentRequest.stackupFile = {
          file_name: stackupFile.file_name,
          file_hash: stackupFile.file_hash,
        }
        fileHashKeys.push(stackupFile)
      }
    }
    if (dataPCBRes?.zip_file) {
      const gerberFile = await readLinkS3AndCreateFile(
        dataPCBRes?.zip_file,
        projectComponentHistoryId,
        sharedKey
      )
      if (gerberFile) {
        dataComponentRequest.gerberFile = {
          file_name: gerberFile.file_name,
          file_hash: gerberFile.file_hash,
        }
        fileHashKeys.push(gerberFile)
      }
      if (dataPCBRes?.files.length) {
        const attachments = await Promise.all(
          dataPCBRes?.files.map((attachment) =>
            readLinkS3AndCreateFile(
              attachment.file,
              projectComponentHistoryId,
              sharedKey
            )
          )
        )
        dataComponentRequest.files = attachments
          .filter((el) => el)
          .map((attachment) => {
            fileHashKeys.push(attachment)
            return {
              file_name: attachment.file_name,
              file_hash: attachment.file_hash,
            }
          })
      }
      if (dataPCBRes?.tempLayers.length) {
        const layers = await Promise.all(
          dataPCBRes?.tempLayers.map(async (layer) => {
            const fileHash = await readLinkS3AndCreateFile(
              layer.file,
              projectComponentHistoryId,
              sharedKey,
              false
            )
            fileHashKeys.push(fileHash)
            return {
              ...layer,
              file_name_hash: fileHash.file_name,
            }
          })
        )
        dataComponentRequest.pcbLayers = layers.map((layer) => {
          return {
            ...pick(layer, [
              "color",
              "custom_sort",
              "side",
              "type",
              "file_name",
              "sequence",
              "sort_index",
              "file_name_hash",
            ]),
          }
        }) as any
      }
      if (dataPCBRes.projectComponentHistoryCommit) {
        const commit = await encryptionController().decrypt(
          dataPCBRes.projectComponentHistoryCommit,
          {
            dataType: "string",
            type: "project",
          }
        )
        const encryptCommit = await encryptionController().encrypt(commit, {
          dataType: "string",
          type: "component-shared",
          encryptionKey: sharedKey,
        })
        dataComponentRequest.commit = encryptCommit
      }
      if (dataPCBRes.specifications.length) {
        const specifications: {
          specification_part_id: string
          value: string
        }[] = []
        let specificationsFiles: any[] = []
        dataPCBRes.specifications[0].parts.forEach(async (part) => {
          if (part.files && part.files.length) {
            specificationsFiles = specificationsFiles.concat(
              part.files.map((el) => {
                return {
                  ...el,
                  specification_part_id: part.id,
                }
              })
            )
          }

          specifications.push({
            specification_part_id: part.id,
            value: part.value,
          })
        })

        if (specifications.length) {
          dataComponentRequest.specifications = specifications
        }
        if (specificationsFiles.length) {
          const specificationsHashFiles = await Promise.all(
            specificationsFiles.map(async (elFile) => {
              const fileHash = await readLinkS3AndCreateFile(
                elFile.file,
                projectComponentHistoryId,
                sharedKey
              )
              fileHashKeys.push(fileHash)
              return {
                file_name: fileHash.file_name,
                file_hash: fileHash.file_hash,
                specification_part_id: elFile.specification_part_id,
              }
            })
          )
          dataComponentRequest.specificationFiles = specificationsHashFiles
        }
      }
      if (dataPCBRes.specification_html_file) {
        dataComponentRequest.specification_html_files =
          dataPCBRes.specification_html_file
      }
    }
  }
  const insertComponentInviteeOther = async (
    projectComponentHistoryId: string,
    dataComponentRequest: SendComponentInviteeRequest,
    type: string,
    fileHashKeys: any[],
    sharedKey: string
  ) => {
    const dataRes = await getProjectComponentOtherMiddleware(
      projectComponentHistoryId,
      type
    )
    if (dataRes?.files.length) {
      const attachments = await Promise.all(
        dataRes?.files.map((attachment) =>
          readLinkS3AndCreateFile(
            attachment.file,
            projectComponentHistoryId,
            sharedKey
          )
        )
      )
      dataComponentRequest.files = attachments
        .filter((el) => el)
        .map((attachment) => {
          fileHashKeys.push(attachment)
          return {
            file_name: attachment.file_name,
            file_hash: attachment.file_hash,
          }
        })
    }
    if (dataRes.projectComponentHistoryCommit) {
      const commit = await encryptionController().decrypt(
        dataRes.projectComponentHistoryCommit,
        {
          dataType: "string",
          type: "project",
        }
      )
      const encryptCommit = await encryptionController().encrypt(commit, {
        dataType: "string",
        type: "component-shared",
        encryptionKey: sharedKey,
      })
      dataComponentRequest.commit = encryptCommit
    }
    if (dataRes.description) {
      const encryptDescription = await encryptionController().encrypt(
        dataRes.description,
        {
          dataType: "string",
          type: "component-shared",
          encryptionKey: sharedKey,
        }
      )
      dataComponentRequest.description = encryptDescription
    }
    if (dataRes.three_d_file) {
      const threeDFile = await readLinkS3AndCreateFile(
        dataRes.three_d_file,
        projectComponentHistoryId,
        sharedKey
      )
      if (threeDFile) {
        dataComponentRequest.threeDFile = {
          file_name: threeDFile.file_name,
          file_hash: threeDFile.file_hash,
        }
        fileHashKeys.push(threeDFile)
      }
    }
    return dataRes
  }

  const syncNewVersionComponentToInvitee = async (
    projectId: string,
    projectBuildId: string,
    projectComponentId: string,
    projectComponentHistoryId: string,
    versionLasted: string,
    isLog: boolean = true,
    defaultInviteeComponents?: ProjectBuildInvitessDetail[]
  ) => {
    const conversationEncryptionKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
    )
    let inviteeComponents = defaultInviteeComponents
    if (isUndefined(defaultInviteeComponents)) {
      const listInvitee = await getProjectBuildInviteeMiddleware(projectBuildId)
      inviteeComponents = listInvitee.filter((el) =>
        includes(
          el.project_build_components.map((e) => e.project_component_id),
          projectComponentId
        )
      )
    }

    if (!inviteeComponents?.length) {
      return
    }
    const componentType = inviteeComponents[0].project_build_components.find(
      (el) => el.project_component_id === projectComponentId
    )?.type

    if (!componentType) {
      return
    }
    const messageBackups = [
      {
        content: `Retrieve data from components: ${versionLasted}`,
        type: MessageBackupType.RETRIEVE_BACKUP_VERSION,
      },
      {
        content: "Decrypting data context and files",
        type: MessageBackupType.DECRYPTING_BACKUP_VERSION,
      },
      {
        content: "Encrypting data context and files",
        type: MessageBackupType.ENCRYPTING_BACKUP_VERSION,
      },
      {
        content: "Creating share version",
        type: MessageBackupType.CREATING_BACKUP_FILE_VERSION,
      },
      {
        content: "Sync version to invitees",
        type: MessageBackupType.DONE_BACKUP_VERSION,
      },
    ]

    setMessageBackups(messageBackups)
    isLoadingBackupInvitee.setValue(true)
    setCurrentMessageBackup(MessageBackupType.RETRIEVE_BACKUP_VERSION)
    setTimeout(() => {
      setCurrentMessageBackup(MessageBackupType.DECRYPTING_BACKUP_VERSION)
    }, 200)
    const dataCreateNewVersion = await createNewComponentHistoryByOwner(
      projectId,
      componentType,
      projectComponentHistoryId
    )
    setCurrentMessageBackup(MessageBackupType.ENCRYPTING_BACKUP_VERSION)
    const { dataComponentRequest, fileHashKeys, sharedKey } =
      dataCreateNewVersion

    if (dataComponentRequest.sharedKey) {
      console.log("-------START SYNC VERSION INVITEE-----------")
      console.log("Share key", dataComponentRequest.sharedKey)
      console.log("-------END SYNC VERSION INVITEE-----------")
      const inviteHashKeys: {
        encryptedKey: string
        project_component_history_id: string
        invitee_id: string
        user_id: string
      }[] = []

      const ccHashKeys: {
        encryptedKey: string
        user_id: string
      }[] = []

      const logs: {
        activity: string
        comment: string
        conversation_id: string
        component_code: string
      }[] = []

      setTimeout(() => {
        setCurrentMessageBackup(MessageBackupType.CREATING_BACKUP_FILE_VERSION)
      }, 200)

      inviteeComponents.forEach((invite) => {
        const history = invite.project_build_components.find(
          (el) => el.project_component_id === projectComponentId
        )
        if (invite.publicKey && history) {
          const publicKey = forge.pki.publicKeyFromPem(invite.publicKey)

          const encryptSharedKey = publicKey.encrypt(
            dataComponentRequest.sharedKey || "",
            "RSA-OAEP"
          )
          inviteHashKeys.push({
            encryptedKey: encryptSharedKey,
            project_component_history_id: history.project_component_history_id,
            invitee_id: invite.id,
            user_id: invite.user_id,
          })
        }
        if (invite.publicKeyCCs && invite.publicKeyCCs.length && history) {
          invite.publicKeyCCs.forEach((cc) => {
            if (cc.publicKey) {
              try {
                const publicKeyCC = forge.pki.publicKeyFromPem(
                  cc.publicKey as any
                )
                const encryptSharedKeyCC = publicKeyCC.encrypt(
                  sharedKey,
                  "RSA-OAEP"
                )
                ccHashKeys.push({
                  encryptedKey: encryptSharedKeyCC,
                  user_id: cc.user_id,
                })
              } catch (error) {}
            }
          })
        }
        if (invite.conversation_id && history && isLog) {
          const conversationEncryptionKey =
            conversationEncryptionKeys[invite.conversation_id]
          const component_code = `${history.code}/${versionLasted}`
          const comment = activityLogHelper.toEncryptedMessage(
            ActivityLogType.CommentSyncDataFromBuild,
            {
              componentCode: component_code,
            },
            conversationEncryptionKey
          )
          const activity = activityLogHelper.toEncryptedMessage(
            ActivityLogType.SyncFromBuild,
            {}
          )
          logs.push({
            activity,
            comment,
            conversation_id: invite.conversation_id,
            component_code,
          })
        }
      })
      let projectComponentHistoryShareId: string = ""
      if (fileHashKeys.length) {
        const fileHashSuccess = await Promise.all(
          fileHashKeys.map(async (elFile) => {
            return await postUploadFileInviteeMiddleware({
              file_name: elFile.file_name,
              file_hash: elFile.file_hash,
              blob: elFile.blob,
            })
          })
        )
        if (fileHashSuccess.some((el) => el)) {
          const dataSyncRes = await syncNewVersionComponentInviteeMiddleWare(
            dataComponentRequest,
            inviteHashKeys,
            projectComponentHistoryId,
            logs
          )
          if (dataSyncRes.project_component_history_share_id) {
            projectComponentHistoryShareId =
              dataSyncRes.project_component_history_share_id
          }
        }
      } else {
        const dataSyncRes = await syncNewVersionComponentInviteeMiddleWare(
          dataComponentRequest,
          inviteHashKeys,
          projectComponentHistoryId,
          logs
        )
        if (dataSyncRes.project_component_history_share_id) {
          projectComponentHistoryShareId =
            dataSyncRes.project_component_history_share_id
        }
      }

      if (ccHashKeys.length && projectComponentHistoryShareId) {
        await createEncryptionKeysMiddleware(
          ccHashKeys.map((el) => {
            return {
              encrypted_key: el.encryptedKey,
              model: "project_build_components",
              relation_id: projectComponentHistoryShareId,
              type: KeyEncryptionType.ComponentShareKey,
              user_id: el.user_id,
              is_expired: true,
            }
          })
        )
      }
      setCurrentMessageBackup(MessageBackupType.DONE_BACKUP_VERSION)
      setTimeout(() => {
        isLoadingBackupInvitee.setValue(false)
      }, 2000)
    } else {
      errorMessageBackup.setValue(true)
      setTimeout(() => {
        isLoadingBackupInvitee.setValue(false)
      }, 2000)
    }
  }

  const syncNewVersionComponentInvitee = async (
    inviteBuilComponent: InviteeBuilComponentDetail[],
    projectId: string,
    originInvites: ProjectBuildInvitessDetail,
    emails?: string[],
    isGenerateDataUser: boolean = false
  ) => {
    let newInviteBuilComponent = cloneDeep(inviteBuilComponent)
    const generateDataKeyPairUsers: {
      publicKey: string
      encryptedPrivateKey: string
      userId: string
    }[] = []
    const componentShareKeys: InviteeBuilComponentDetail[] = []
    newInviteBuilComponent.forEach((component) => {
      if (
        component.project_component_history_latest_id &&
        component.project_component_history_id !==
          component.project_component_history_latest_id
      ) {
        componentShareKeys.push(component)
      } else {
        const userBackupComponents = isUndefined(
          component.user_backup_components
        )
          ? []
          : component.user_backup_components
        const notBackup = userBackupComponents.some((item) => !item.is_backup)
        if (
          notBackup &&
          (!component.project_component_history_share_id ||
            !component.componentShareKey)
        ) {
          componentShareKeys.push(component)
        }
      }
    })
    if (componentShareKeys.length) {
      const conversationEncryptionKeys = JSON.parse(
        localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
      )
      const messageBackups = [
        {
          content: `Retrieve data from components: ${componentShareKeys
            .map((el) => el.code)
            .join(", ")}`,
          type: MessageBackupType.RETRIEVE_BACKUP_VERSION,
        },
        {
          content: "Decrypting data context and files",
          type: MessageBackupType.DECRYPTING_BACKUP_VERSION,
        },
        {
          content: "Encrypting data context and files",
          type: MessageBackupType.ENCRYPTING_BACKUP_VERSION,
        },
        {
          content: "Creating share version",
          type: MessageBackupType.CREATING_BACKUP_FILE_VERSION,
        },
        {
          content: "Sync version to invitees",
          type: MessageBackupType.DONE_BACKUP_VERSION,
        },
      ]

      setMessageBackups(messageBackups)
      isLoadingBackupInvitee.setValue(true)
      setCurrentMessageBackup(MessageBackupType.RETRIEVE_BACKUP_VERSION)
      setTimeout(() => {
        setCurrentMessageBackup(MessageBackupType.DECRYPTING_BACKUP_VERSION)
      }, 200)
      setTimeout(() => {
        setCurrentMessageBackup(MessageBackupType.ENCRYPTING_BACKUP_VERSION)
      }, 400)

      const componentShares = await Promise.all(
        componentShareKeys.map(async (component) => {
          const componentHistoryId =
            component.project_component_history_latest_id &&
            component.project_component_history_latest_id !==
              component.project_component_history_id
              ? component.project_component_history_latest_id
              : component.project_component_history_id

          const { dataComponentRequest, fileHashKeys, sharedKey } =
            await createNewComponentHistoryByOwner(
              projectId,
              component.type,
              componentHistoryId
            )
          if (dataComponentRequest.sharedKey) {
            console.log("-------START SHARE COMPONENT-----------")
            console.log("Conversation code", originInvites.conversation_code)
            console.log("Conversation ID", originInvites.conversation_id)
            console.log("Share key", sharedKey)
            console.log(
              "Invitee",
              `${originInvites.name} / ${originInvites.email} / ${originInvites.user_id}`
            )
            console.log("CC", originInvites.cc)
            console.log("-------END SHARE COMPONENT-----------")
            const inviteHashKeys: {
              encryptedKey: string
              project_component_history_id: string
              invitee_id: string
              user_id: string
            } = {
              encryptedKey: "",
              project_component_history_id:
                component.project_component_history_id,
              invitee_id: originInvites.id,
              user_id: originInvites.user_id,
            }
            const logs: {
              activity: string
              comment: string
              conversation_id: string
              component_code: string
              conversation_code: string
            }[] = []

            if (
              originInvites.conversation_id &&
              component.project_component_history_latest_id &&
              component.project_component_history_latest_id !==
                component.project_component_history_id
            ) {
              const conversationEncryptionKey =
                conversationEncryptionKeys[originInvites.conversation_id]
              const component_code = `${component.code}/${component.version_code_latest}`
              const comment = activityLogHelper.toEncryptedMessage(
                ActivityLogType.CommentSyncDataFromBuild,
                {
                  componentCode: component_code,
                },
                conversationEncryptionKey
              )
              const activity = activityLogHelper.toEncryptedMessage(
                ActivityLogType.SyncFromBuild,
                {}
              )
              logs.push({
                activity,
                comment,
                conversation_id: originInvites.conversation_id,
                component_code,
                conversation_code: originInvites.conversation_code,
              })
            }

            const dataRes = await syncNewVersionComponentInviteeMiddleWare(
              dataComponentRequest,
              [inviteHashKeys],
              componentHistoryId,
              logs
            )
            if (fileHashKeys.length) {
              fileHashKeys.forEach((file) => {
                postUploadFileInviteeMiddleware({
                  file_name: file.file_name,
                  file_hash: file.file_hash,
                  blob: file.blob,
                })
              })
            }
            return {
              project_component_history_share_id:
                dataRes?.project_component_history_share_id || "",
              project_component_history_id: componentHistoryId,
              project_component_history_latest_id: componentHistoryId,
              sharedKey: dataComponentRequest.encryptSharedKey,
            }
          }
          return {
            project_component_history_share_id: "",
            project_component_history_id:
              component.project_component_history_id,
            project_component_history_latest_id:
              component.project_component_history_latest_id,
            sharedKey: "",
          }
        })
      )
      setCurrentMessageBackup(MessageBackupType.CREATING_BACKUP_FILE_VERSION)
      newInviteBuilComponent = newInviteBuilComponent.map((el) => {
        const componentShareLatest = componentShares.find(
          (share) =>
            share.project_component_history_latest_id ===
              el.project_component_history_latest_id &&
            share.sharedKey &&
            share.project_component_history_share_id
        )
        if (componentShareLatest) {
          return {
            ...el,
            project_component_history_share_id:
              componentShareLatest.project_component_history_share_id || "",
            componentShareKey: componentShareLatest.sharedKey || "",
            project_component_history_id:
              componentShareLatest.project_component_history_id || "",
            project_component_history_latest_id:
              componentShareLatest.project_component_history_latest_id,
          }
        }
        return el
      })
    }
    const projectKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
    )
    const projectKey = projectKeys[projectId]

    const conversationEncryptionKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
    )
    const buildAdditionalInfoEncryptionKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.buildAdditionalInfoEncryptionKeys) ||
        "{}"
    )
    const keys: EncryptionKeyInput[] = []
    const conversationKeyItem =
      conversationEncryptionKeys[originInvites.conversation_id]
    const buildAdEncryptionKeyItem =
      buildAdditionalInfoEncryptionKeys[originInvites.project_build_id]
    await Promise.all(
      newInviteBuilComponent.map(async (componentShareKey) => {
        const userNotBackupComponents =
          componentShareKey.user_backup_components
            ?.filter((userBackup) => !userBackup.is_backup)
            .map((el) => el.user_id) || []

        const sharedKey = await encryptionController().decrypt(
          componentShareKey.componentShareKey,
          {
            dataType: "string",
            type: "component-shared",
            encryptionKey: projectKey,
          }
        )

        if (componentShareKey.project_component_history_share_id) {
          let publicKeyInvitee = originInvites.publicKey
          if (!publicKeyInvitee) {
            if (isGenerateDataUser) {
              const dataKeyPair = await generateDataKeyPairMiddleware()
              publicKeyInvitee = dataKeyPair.publicKey
              generateDataKeyPairUsers.push({
                publicKey: dataKeyPair.publicKey,
                encryptedPrivateKey: dataKeyPair.encryptedPrivateKey,
                userId: originInvites.user_id,
              })
            }
          }
          if (
            publicKeyInvitee &&
            includes(userNotBackupComponents, originInvites.user_id) &&
            sharedKey
          ) {
            const publicKey = forge.pki.publicKeyFromPem(publicKeyInvitee)
            const encryptSharedKeyInvitee = publicKey.encrypt(
              sharedKey,
              "RSA-OAEP"
            )
            if (
              (!isUndefined(emails) && includes(emails, originInvites.email)) ||
              isUndefined(emails)
            ) {
              keys.push({
                encrypted_key: encryptSharedKeyInvitee,
                model: "project_build_components",
                relation_id:
                  componentShareKey.project_component_history_share_id,
                type: KeyEncryptionType.ComponentShareKey,
                user_id: originInvites.user_id,
                is_expired: true,
              })

              const buildKeyInviteeExisted = keys.find(
                (el) =>
                  el.user_id === originInvites.user_id &&
                  el.model === "build_additional_infos"
              )
              if (!buildKeyInviteeExisted) {
                const buildInviteeEncryptionKey = publicKey.encrypt(
                  buildAdEncryptionKeyItem as string,
                  "RSA-OAEP"
                )
                keys.push({
                  encrypted_key: buildInviteeEncryptionKey,
                  model: "build_additional_infos",
                  relation_id: originInvites.project_build_id,
                  type: KeyEncryptionType.ModuleKey,
                  user_id: originInvites.user_id,
                })
              }
              const conversationKeyInviteeExisted = keys.find(
                (el) =>
                  el.user_id === originInvites.user_id &&
                  el.model === "conversations"
              )
              if (!conversationKeyInviteeExisted) {
                const conversationInviteeEncryptionKey = publicKey.encrypt(
                  conversationKeyItem as string,
                  "RSA-OAEP"
                )
                keys.push({
                  encrypted_key: conversationInviteeEncryptionKey,
                  model: "conversations",
                  relation_id: originInvites.conversation_id,
                  type: KeyEncryptionType.ModuleKey,
                  user_id: originInvites.user_id,
                })
              }
            }
          }

          if (originInvites.publicKeyCCs && originInvites.publicKeyCCs.length) {
            await Promise.all(
              originInvites.publicKeyCCs.map(async (cc) => {
                let publicKeyCC = cc.publicKey
                if (!publicKeyCC && isGenerateDataUser) {
                  const dataKeyPairCC = await generateDataKeyPairMiddleware()
                  publicKeyCC = dataKeyPairCC.publicKey
                  generateDataKeyPairUsers.push({
                    publicKey: dataKeyPairCC.publicKey,
                    encryptedPrivateKey: dataKeyPairCC.encryptedPrivateKey,
                    userId: cc.user_id,
                  })
                }
                if (
                  publicKeyCC &&
                  includes(userNotBackupComponents, cc.user_id) &&
                  sharedKey
                ) {
                  try {
                    const publicKeyCCFrom =
                      forge.pki.publicKeyFromPem(publicKeyCC)
                    const encryptSharedKeyCC = publicKeyCCFrom.encrypt(
                      sharedKey,
                      "RSA-OAEP"
                    )
                    if (
                      (!isUndefined(emails) && includes(emails, cc.email)) ||
                      isUndefined(emails)
                    ) {
                      keys.push({
                        encrypted_key: encryptSharedKeyCC,
                        model: "project_build_components",
                        relation_id:
                          componentShareKey.project_component_history_share_id ||
                          "",
                        type: KeyEncryptionType.ComponentShareKey,
                        user_id: cc.user_id,
                        is_expired: true,
                      })

                      const buildKeyCCExisted = keys.find(
                        (el) =>
                          el.user_id === cc.user_id &&
                          el.model === "build_additional_infos"
                      )
                      if (!buildKeyCCExisted) {
                        const buildCCEncryptionKey = publicKeyCCFrom.encrypt(
                          buildAdEncryptionKeyItem as string,
                          "RSA-OAEP"
                        )
                        keys.push({
                          encrypted_key: buildCCEncryptionKey,
                          model: "build_additional_infos",
                          relation_id: originInvites.project_build_id,
                          type: KeyEncryptionType.ModuleKey,
                          user_id: cc.user_id,
                        })
                      }
                      const conversationKeyCCExisted = keys.find(
                        (el) =>
                          el.user_id === cc.user_id &&
                          el.model === "conversations"
                      )
                      if (!conversationKeyCCExisted) {
                        const conversationCCEncryptionKey =
                          publicKeyCCFrom.encrypt(
                            conversationKeyItem as string,
                            "RSA-OAEP"
                          )
                        keys.push({
                          encrypted_key: conversationCCEncryptionKey,
                          model: "conversations",
                          relation_id: originInvites.conversation_id,
                          type: KeyEncryptionType.ModuleKey,
                          user_id: cc.user_id,
                        })
                      }
                    }
                  } catch (error) {}
                }
              })
            )
          }
        }

        return true
      })
    )
    if (keys.length) {
      await createEncryptionKeysMiddleware(keys)
    }
    if (!isUndefined(emails)) {
      setCurrentMessageBackup(MessageBackupType.DONE_BACKUP_VERSION)
      setTimeout(() => {
        isLoadingBackupInvitee.setValue(false)
      }, 2000)
    }
    return generateDataKeyPairUsers
  }

  return {
    createNewComponentHistoryById,
    createNewComponentHistoryByOwner,
    syncNewVersionComponentToInvitee,
    syncNewVersionComponentInvitee,
    messageBackups,
    currentMessageBackup,
    errorMessageBackup,
    isLoadingBackupInvitee,
  }
}
