import Axios from "axios"
import { IUseDefaultValueProps, useWindowSize } from "helpers/hooks"
import {
  formatDate,
  getContentFromConversationNoteFile,
  getPrivateAssetURI,
  getPrivateKeyByUserId,
  validateConversationNoteFile,
} from "helpers/utils"
import { debounce, isEmpty } from "lodash"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"
import { Note } from "../../conversations.type"
import { ReactComponent as TimestampIcon } from "assets/images/icons/icon-editor-timestamp.svg"
import { ReactComponent as EditorFileIcon } from "assets/images/icons/icon-editor-file.svg"
import { ReactComponent as EditorTextBoldIcon } from "assets/images/icons/icon-editor-bold.svg"
import { ReactComponent as EditorTextItalicIcon } from "assets/images/icons/icon-editor-italic.svg"
import { ReactComponent as EditorTextColorIcon } from "assets/images/icons/icon-editor-text-color.svg"
import { ReactComponent as EditorTextBulletListIcon } from "assets/images/icons/icon-editor-bullet-list.svg"
import { ReactComponent as EditorTextOrderListIcon } from "assets/images/icons/icon-editor-ordered-list.svg"
import { ReactComponent as EditorImageIcon } from "assets/images/icons/icon-editor-image.svg"

import FileEditorCard from "pages/project-component-detail/molecules/FileEditorCard"
import { deleteNoteImageMiddleware } from "pages/conversations/conversations.api"
import ImageResize from "quill-image-resize-module-react"
import Tippy from "@tippyjs/react"
import ReactDOMServer from "react-dom/server"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { encryptionHelper } from "helpers/encryption"
import { getStoreData, Stores } from "services/indexedDB"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"

window.Quill = Quill
Quill.register("modules/imageResize", ImageResize)
interface NoteFile {
  file: string
  file_name: string
  url: string
}
interface Props {
  activeConversationId: string
  noteDetail: Note
  onEditNotes: (notes: string) => void
  onEditImage: (image: NoteFile, type: "add" | "delete") => void
  onInsertAttachments: (attachments: NoteFile[]) => void
  isSavingNote: IUseDefaultValueProps
}
const icons = Quill.import("ui/icons")
const CustomToolbar = () => {
  return (
    <div
      id="toolbar"
      className="
     rounded-full p-2 flex bg-black-04 gap-1 items-center"
    >
      <div className="">
        <select
          className="ql-header"
          defaultValue={""}
          onChange={(e) => e.persist()}
        >
          <option value="1" />
          <option value="2" />
          <option selected />
        </select>
      </div>
      <div className="h-[20px] bg-stroke  w-[0.5px]"></div>

      <Tippy placement="top" content={"Bold"}>
        <button className="ql-bold" />
      </Tippy>
      <Tippy placement="top" content={"Italic"}>
        <button className="ql-italic" />
      </Tippy>
      <Tippy placement="top" content={"Text color"}>
        <div>
          <select className="ql-color">
            <option value="red" />
            <option value="green" />
            <option value="blue" />
            <option value="orange" />
            <option value="violet" />
            <option value="#d0d1d2" />
            <option selected />
          </select>
        </div>
      </Tippy>
      <Tippy placement="top" content={"Ordered list"}>
        <button className="ql-list" value={"ordered"} />
      </Tippy>
      <Tippy placement="top" content={"Bulleted list"}>
        <button className="ql-list" value={"bullet"} />
      </Tippy>

      <Tippy placement="top" content={"Image"}>
        <button className="ql-image"></button>
      </Tippy>
      <Tippy placement="top" content={"Add timestamp"}>
        <button className="ql-insertTimestamp">
          <TimestampIcon />
        </button>
      </Tippy>
      <Tippy placement="top" content={"Files"}>
        <button className="ql-insertFiles">
          <EditorFileIcon />
        </button>
      </Tippy>
    </div>
  )
}

const NotesEditor = (props: Props) => {
  const {
    onEditNotes,
    noteDetail,
    activeConversationId,
    onEditImage,
    onInsertAttachments,
    isSavingNote,
  } = props
  const { note, files } = noteDetail
  const quillRef = useRef<any>(activeConversationId)
  const [value, setValue] = useState(note)
  const { isMobile } = useWindowSize()

  const imageHandler = async () => {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute("accept", "image/*")
    input.click()
    input.onchange = async () => {
      const file: any = input && input.files ? input.files[0] : null
      if (!file) return
      const formData = new FormData()
      formData.append("file", file)
      const data = await upload(formData)
      const quill = quillRef?.current?.getEditor()
      const cursorPosition = quill.getSelection()?.index || 0
      quill.insertEmbed(cursorPosition, "image", data.url)
      quill.setSelection(cursorPosition + 1)
    }
  }
  const insertTimestamp = () => {
    const currentTimestamp = formatDate(new Date())
    const quill = quillRef?.current?.getEditor()
    const cursorPosition = quill.getSelection()?.index || 0
    const oldFormat = quill.getFormat()
    quill.insertText(cursorPosition, currentTimestamp, { color: "#FF8C00" })
    quill.setSelection(cursorPosition + currentTimestamp.length)
    quill.format("color", "black")
    for (const key in oldFormat) {
      quill.format(key, oldFormat[key])
    }
  }
  const insertFiles = async () => {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute("accept", "*")
    input.setAttribute("multiple", "true")
    input.click()

    input.onchange = async () => {
      const files = input ? input.files : null
      if (!files) return
      const formData = new FormData()
      for (const [, newFile] of Object.entries(files)) {
        formData.append("files", newFile)
      }
      await uploadAttachments(formData)
    }
  }
  const upload = async (formData: FormData) => {
    const file = formData.get("file") as any
    isSavingNote.setValue(true)
    formData.delete("file")
    const userVaultKey = localStorage.getItem(EncryptionKeys.userVaultKey) || ""
    const result = isEmpty(userVaultKey)
      ? file
      : await encryptionController().encrypt(file, {
          dataType: "file",
          type: "conversation_note",
        })
    const blob = new Blob([result], { type: file.type })
    formData.append("file", blob, file.name)

    const response = await Axios.post(
      `/api/conversation/notes/${activeConversationId}/upload-image`,
      formData
    )
    // get base64 and include into note here
    const fileUrl = getPrivateAssetURI(response.data.data.file, {
      conversation_id: activeConversationId,
    })
    const base64Str = await getContentFromConversationNoteFile(fileUrl, true)
    const addedUrlData = { ...response.data.data, url: base64Str as string }
    onEditImage(addedUrlData, "add")
    isSavingNote.setValue(false)

    return addedUrlData
  }
  const uploadAttachments = async (formData: FormData) => {
    isSavingNote.setValue(true)
    const userVaultKey = localStorage.getItem(EncryptionKeys.userVaultKey) || ""
    const privateKeyByUserId = (await getPrivateKeyByUserId(false)) as string
    if (!privateKeyByUserId) {
      toast(
        <LabelNotificationPage
          messenger={"Private key not found!"}
          type={"error"}
        />
      )
      return
    }
    const files = formData.getAll("files") as File[]
    let fileHashKeys: any = []
    formData.delete("files")
    await Promise.all(
      files.map(async (file) => {
        const result = isEmpty(userVaultKey)
          ? file
          : await encryptionController().encrypt(file, {
              dataType: "file",
              type: "conversation_note",
            })
        const blob = new Blob([result], { type: file.type })
        formData.append("files", blob, file.name)
        const rawFileBlob = new Blob([file], { type: file.type })
        const fileText = await rawFileBlob.text()
        const signatureAndHashFile =
          encryptionHelper.createSignatureAndHashFile(
            fileText,
            true,
            privateKeyByUserId
          )
        if (signatureAndHashFile.signature) {
          fileHashKeys = fileHashKeys.concat([
            {
              file_name: file.name,
              file_hash: signatureAndHashFile.signature,
            },
          ])
        }
      })
    )
    formData.append("file_hashs", JSON.stringify(fileHashKeys))
    const res = await Axios.post(
      `/api/conversation/notes/${activeConversationId}/upload-attachments`,
      formData
    )
    await Promise.all(
      res.data.data.map(async (file) => {
        const found = fileHashKeys.find(
          (item) => item.file_name === file.file_name
        )
        await validateConversationNoteFile(found?.file_hash || "", {
          file_key: file.file,
        })
        return true
      })
    )
    onInsertAttachments(res.data.data)
    isSavingNote.setValue(false)
    return res.data.data
  }
  const onChangeEditor = (notes: string) => {
    // the url in image tag got encoded from & -> &amp so we need to replace it back to apply our logic
    const editedNote = notes.replaceAll("&amp;", "&")
    if (value === editedNote) {
      return
    }
    setValue(notes)
    debouceOnUpdateNotes(editedNote)
  }
  const debouceOnUpdateNotes = useCallback(debounce(onEditNotes, 1000), [])
  const onDeleteImage = (file: NoteFile) => {
    isSavingNote.setValue(true)
    deleteNoteImageMiddleware(activeConversationId, file.file)
      .then((_res) => {
        // handle delete image tag
        const cloneNotes = value
        let newNote = cloneNotes.replaceAll("&amp;", "&")
        const urlIndex = newNote.lastIndexOf(file.url)

        if (urlIndex >= 0) {
          const endImageTagPosition = newNote.indexOf(">", urlIndex)
          const deletedImageTag = newNote.substring(
            urlIndex - 10,
            endImageTagPosition + 1
          )
          newNote = newNote.replaceAll(deletedImageTag, "")
          onChangeEditor(newNote)
        }
        onEditImage(file, "delete")
      })
      .catch((error) => {
        console.log("error", error.response?.data?.message)
      })
      .finally(() => {
        isSavingNote.setValue(false)
      })
  }
  const modules = useMemo(() => {
    icons.bold = ReactDOMServer.renderToString(<EditorTextBoldIcon />)
    icons.italic = ReactDOMServer.renderToString(<EditorTextItalicIcon />)
    icons.color = ReactDOMServer.renderToString(<EditorTextColorIcon />)
    icons.list.bullet = ReactDOMServer.renderToString(
      <EditorTextBulletListIcon />
    )
    icons.list.ordered = ReactDOMServer.renderToString(
      <EditorTextOrderListIcon />
    )
    icons.image = ReactDOMServer.renderToString(<EditorImageIcon />)
    icons.header[2] = ReactDOMServer.renderToString(<EditorImageIcon />)
    return {
      toolbar: {
        container: "#toolbar",
        handlers: {
          image: imageHandler,
          insertTimestamp,
          insertFiles,
        },
      },
      clipboard: {
        matchVisual: false,
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize"],
      },
    }
  }, [activeConversationId])
  return (
    <div className="text-editor relative h-[78vh] w-full">
      <ReactQuill
        key={`${activeConversationId}editor`}
        className={`w-full h-full overflow-auto text-black leading-3`}
        ref={quillRef}
        value={value}
        onChange={onChangeEditor}
        modules={modules}
        readOnly={false}
        theme="snow"
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
          // "imagewithstyle",
          "code",
          "color",
          "background",
          "code-block",
          "align",
          "width",
        ]}
      />

      {/* {isMobile ? null : ( */}
      <div className=" flex flex-col gap-2 border-gray-400 border-t-[1px] rounded-2 mt-2 ">
        <div className="flex items-center ml-3 mt-3 max-h-[60px] overflow-y-auto w-full flex-wrap">
          {files &&
            files.map((file, index) => (
              // <a key={index} href={file.url} target="_blank" rel="noreferrer">
              <FileEditorCard
                key={file.file}
                index={index}
                name={file.file_name}
                isDownload={true}
                isDeleted={true}
                urlFile={file.file}
                handleDeleteFile={(_index) => () => onDeleteImage(file)}
                isDownloadConversationNote={true}
              />
              // </a>
            ))}
        </div>
        <div className="flex items-center justify-center">
          <CustomToolbar />
        </div>
      </div>
      {/* )} */}
    </div>
  )
}
export default NotesEditor
