import { Route, Router, Switch } from "react-router-dom"
import { history } from "helpers/history"
import { PATH } from "constants/path"

import Login from "pages/auth/Login"
import SignUp from "pages/auth/SignUp"
import ForgetPassword from "pages/auth/ForgetPassword"
import PasswordReset from "pages/auth/PasswordReset"
import PasswordResetSuccess from "pages/auth/PasswordResetSuccess"
import EmailVerification from "pages/auth/EmailVerification"
import ConfirmEmail from "pages/auth/ConfirmEmail"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import {
  API_URL_RECAPTCHA,
  UMAMI_TRACKING_ID,
  UMAMI_URL,
} from "config/environments"
import Main from "pages/projects/Projects"
import PrivateRoute from "components/Router/PrivateRoute"
import PublicRoute from "components/Router/PublicRoute"
import General from "pages/auth/General"
import ForceEncryption from "pages/auth/ForceEncryption"
import NotFoundPage from "pages/layout/NotFoundPage"
import DraftBuild from "pages/project-build/DraftBuild"
import PCBTemplate from "pages/project-component-detail/PCBTemplate"
import BOMTemplate from "pages/project-component-detail/BOMTemplate"
import OtherTemplate from "pages/project-component-detail/OtherTemplate"
import MechTemplate from "pages/project-component-detail/MechTemplate"
import Dashboard from "pages/dashboard/Dashboard"
import Conversations from "pages/conversations/Conversations"
import InviteSentSignUp from "pages/auth/InviteSentSignUp"
import InviteePCBComponent from "pages/conversations/organisms/invitees-component/InviteePCBComponent"
import InviteeBOMComponent from "pages/conversations/organisms/invitees-component/InviteeBOMComponent"
import InviteeOtherComponent from "pages/conversations/organisms/invitees-component/InviteeOtherComponent"
import InviteeMechComponent from "pages/conversations/organisms/invitees-component/InviteeMechComponent"
import TermsAndPolicyPage from "pages/terms-policy/TermsAndPolicyPage"
import ProjectSearchPage from "pages/projects/ProjectSearchPage"
import ProjectComponent from "pages/project-component/ProjectComponent"
import BuildOverview from "pages/build-overview/BuildOverView"
import { setupInterceptors } from "services/axios"
import { useEffect } from "react"
import { debounce } from "lodash"
import StorageUsagePage from "pages/storage-usage/StorageUsagePage"
import { initDB } from "services/indexedDB"
import ProjectSync from "pages/projects/ProjectSync"
import { ChangePhoneNumber } from "pages/auth/ChangePhoneNumber"
// setup interceptor
setupInterceptors()
declare global {
  interface Window {
    umami?: {
      track: (event: string, payload?: Record<string, any>) => void
    }
    Quill: any
  }
}
export const AppRouter = () => {
  useEffect(() => {
    const resize = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vhi", `${vh}px`)
    }
    resize()
    const umamiScript = document.createElement("script")
    umamiScript.async = true
    umamiScript.src = UMAMI_URL // Replace with your Umami instance URL
    umamiScript.setAttribute("data-website-id", UMAMI_TRACKING_ID) // Replace with your Tracking ID
    document.body.appendChild(umamiScript)
    const debounceResize = debounce(resize, 50)
    window.addEventListener("resize", debounceResize)
    initDB()
      .then((res) => {
        // console.log(res)
      })
      .catch((error) => {
        console.log(error)
      })
    return () => {
      window.removeEventListener("resize", debounceResize)
      document.body.removeChild(umamiScript)
    }
  }, [])

  return (
    <Router history={history}>
      <Switch>
        <PublicRoute
          exact
          path={PATH.login}
          component={
            <GoogleReCaptchaProvider reCaptchaKey={API_URL_RECAPTCHA}>
              <Login />
            </GoogleReCaptchaProvider>
          }
        />
        <PublicRoute
          exact
          path={PATH.signUp}
          component={
            <GoogleReCaptchaProvider reCaptchaKey={API_URL_RECAPTCHA}>
              <SignUp />
            </GoogleReCaptchaProvider>
          }
        />
        <PublicRoute
          exact
          path={PATH.forget}
          component={
            <GoogleReCaptchaProvider reCaptchaKey={API_URL_RECAPTCHA}>
              <ForgetPassword />
            </GoogleReCaptchaProvider>
          }
        />
        <PublicRoute
          path={PATH.reset}
          component={
            <GoogleReCaptchaProvider reCaptchaKey={API_URL_RECAPTCHA}>
              <PasswordReset />
            </GoogleReCaptchaProvider>
          }
        />
        <PublicRoute
          exact
          path={PATH.resetSuccess}
          component={<PasswordResetSuccess />}
        />
        <Route
          exact
          path={PATH.termsAndServices}
          render={() => <TermsAndPolicyPage />}
        />

        <Route
          exact
          path={PATH.policies}
          render={() => <TermsAndPolicyPage />}
        />
        <PublicRoute
          path={PATH.emailVerification}
          component={<EmailVerification />}
        />
        <PublicRoute
          path={PATH.signingUpMessage}
          component={<EmailVerification />}
        />
        <PublicRoute path={PATH.confirmEmail} component={<ConfirmEmail />} />
        <PublicRoute
          path={PATH.inviteSentSignUp}
          component={
            <GoogleReCaptchaProvider reCaptchaKey={API_URL_RECAPTCHA}>
              <InviteSentSignUp />
            </GoogleReCaptchaProvider>
          }
        />
        {/* <Route
          path={PATH.secretChat}
          render={() => <SecretConversation />}
          exact
        /> */}
        <PrivateRoute exact path="/" component={<Main />} />
        <PrivateRoute exact path={PATH.general} component={<General />} />
        <PrivateRoute
          exact
          path={PATH.changePhoneNumber}
          component={<ChangePhoneNumber />}
        />

        <PrivateRoute
          exact
          path={PATH.enableEncryption}
          component={<ForceEncryption />}
        />
        <PrivateRoute exact path={PATH.dashboard} component={<Dashboard />} />
        <PrivateRoute
          exact
          path={PATH.StorageUsage}
          component={<StorageUsagePage />}
        />
        <PrivateRoute exact path={PATH.projects} component={<Main />} />
        <PrivateRoute
          exact
          path={PATH.generalSearch}
          component={<ProjectSearchPage />}
        />
        <PrivateRoute
          exact
          path={PATH.buildsOverview}
          component={<BuildOverview />}
        />
        <PrivateRoute
          exact
          path={PATH.buildOverview}
          component={<BuildOverview />}
        />
        <PrivateRoute
          exact
          path={PATH.defaultBuildOverview}
          component={<BuildOverview />}
        />
        <PrivateRoute
          exact
          path={PATH.conversations}
          component={<Conversations />}
        />
        <PrivateRoute
          path={PATH.inviteesPCB}
          component={<InviteePCBComponent />}
        />
        <PrivateRoute
          path={PATH.inviteesBuildPCB}
          component={<InviteePCBComponent />}
        />
        <PrivateRoute
          path={PATH.inviteesBOM}
          component={<InviteeBOMComponent />}
        />
        <PrivateRoute
          path={PATH.inviteesBuildBOM}
          component={<InviteeBOMComponent />}
        />
        <PrivateRoute
          path={PATH.inviteesMechComponent}
          component={<InviteeMechComponent />}
        />
        <PrivateRoute
          path={PATH.inviteesBuildMechComponent}
          component={<InviteeMechComponent />}
        />
        <PrivateRoute
          path={PATH.inviteesOther}
          component={<InviteeOtherComponent />}
        />
        <PrivateRoute
          path={PATH.inviteesBuildOther}
          component={<InviteeOtherComponent />}
        />
        {/* <PrivateRoute exact path={PATH.settings} component={<General />} /> */}
        <PrivateRoute
          exact
          path={PATH.projectComponent}
          component={<ProjectComponent />}
        />
        <PrivateRoute
          exact
          path={PATH.projectSensitiveData}
          component={<ProjectSync />}
        />

        <PrivateRoute
          exact
          path={PATH.projectComponentPCB}
          component={<PCBTemplate />}
        />
        <PrivateRoute
          exact
          path={PATH.projectComponentBOM}
          component={<BOMTemplate />}
        />
        <PrivateRoute
          path={PATH.projectComponentMech}
          component={<MechTemplate />}
        />
        <PrivateRoute
          path={PATH.projectComponentOther}
          component={<OtherTemplate />}
        />
        <PrivateRoute path={PATH.folder} component={<ProjectComponent />} />
        <PrivateRoute path={PATH.draftBuild} component={<DraftBuild />} />

        <PrivateRoute path="*" component={<NotFoundPage />} />
      </Switch>
    </Router>
  )
}
