import ModalCustom from "components/ModalCustom"
import Button from "components/Button/Button"
import { UserInfo } from "../types"
import FormInputCard from "./FormInputCard"
import InputDefault from "components/Input/InputDefault"
import { twMerge } from "tailwind-merge"
import { useState } from "react"
import { STATUS_INPUT } from "components/Input/types"
import { useBoolean } from "helpers/hooks"
import { useTimeOutResend } from "hooks/useTimeOutResend"
import { postSendSMSOTPMiddleware } from "../services/api"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"

interface Props {
  onCloseModal: () => void
  onClickSubmit: (newCode: string) => void
  userInfo: UserInfo
}
export const EnableAndVerifySMSOTPModal = ({
  onCloseModal,
  onClickSubmit,
  userInfo,
}: Props) => {
  const { countTimeResend, disableResend, timeOutResend } = useTimeOutResend()

  const [otp, setOtp] = useState({
    status: STATUS_INPUT.ERROR,
    label: "Verification code required",
    value: "",
  })
  const isResend = useBoolean()
  const handleChangeOtp = (event: any) => {
    const value = event.target.value
    let status = STATUS_INPUT.DEFAULT
    let label = ""
    if (value.length < 6) {
      label = "Verification code must be a 6 digit number"
      status = STATUS_INPUT.ERROR
    }
    if (value.length === 0) {
      label = "Verification code required"
    }
    setOtp({ value, status, label })
  }

  const handleSendSMSOTP = () => {
    if (disableResend.value) {
      return
    }
    postSendSMSOTPMiddleware("")
      .then(() => {
        countTimeResend(30)
        timeOutResend.setValue(30)
        disableResend.setValue(true)
        isResend.setValue(true)
      })
      .catch((error) => {
        const message = error.response?.data?.message ?? "Something went wrong!"
        toast(<LabelNotificationPage messenger={message} type="error" />)
      })
  }

  const handleDisableButton = () => {
    if (otp.value.length === 6) {
      return false
    }
    return true
  }

  return (
    <ModalCustom
      handleChangeButton={() => onCloseModal}
      label="Enable SMS OTP"
      bodyChildren={
        <div className="flex flex-col">
          <p className="text-[#222222] text-[14px] font-normal leading-[24px] mt-[4px] mb-[12px]">
            {`Please enter the OTP that was sent to: ${userInfo.phone_number}`}
          </p>
          <FormInputCard title="OTP" customClass="mb-3 relative">
            <InputDefault
              type="text"
              value={otp.value}
              onChange={handleChangeOtp}
              status={otp.status}
              labelStatus={otp.label}
              placeholder="Enter Verification code"
              pattern="\d+"
            />
            <p
              className={twMerge(
                "absolute text-[13px] leading-[22px] right-0 top-[-2px]",
                disableResend.value
                  ? `cursor-not-allowed`
                  : `cursor-pointer hover:underline`
              )}
              style={{
                color: disableResend.value ? "#7A7A7A" : "#0a5af5",
              }}
              onClick={handleSendSMSOTP}
            >
              {`${isResend.value ? "Resend" : "Send"} OTP ${
                disableResend.value ? `in (${timeOutResend.value}s)` : ""
              }`}
            </p>
          </FormInputCard>

          <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-between mt-[16px]">
            <Button
              widthBtn={"100%"}
              title="Cancel"
              colorBtn="white"
              onClick={onCloseModal}
            />
            <Button
              widthBtn={"100%"}
              title="Enable"
              colorBtn="yellow"
              onClick={() => onClickSubmit(otp.value)}
              disabled={handleDisableButton()}
            />
          </div>
        </div>
      }
    />
  )
}
