/* eslint-disable react-hooks/exhaustive-deps */
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { umamiTracking } from "helpers/utils"
import { dataEditorDefault } from "pages/project-component-detail/types"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import {
  ConversationMessagesDetailItem,
  ConversationMessagesDetail,
} from "../conversations.type"
import MessagesConversationItemCard from "../molecules/MessagesConversationItemCard"
import { postConversationSendMessageMiddleware } from "../conversations.api"
import { useAppSelector } from "hooks/useApp"
import { useBoolean } from "helpers/hooks"
import FormInputEditorCard from "pages/project-build/molecules/FormInputEditorCard"
import { useAdditionalAndExtraInfoProjectBuild } from "pages/project-build/project-build.hook"
import ModalDelete from "components/ModalCustom/ModalDelete"
import { ReactComponent as IconMessagerDeleteComponent } from "assets/images/icons/icon-messager-delete-component.svg"
import { v4 as uuid } from "uuid"
import { isEmpty, isUndefined } from "lodash"
import { configureStore } from "stores/configureStore"
import { startLoading } from "reducers/loading"
import { useUploadFileWorkerController } from "workers/uploadFileWorkerController"
import {
  getProjectBuildInviteeMiddleware,
  postProjectBuildSendInviteeMiddleware,
} from "pages/project-build/api.services"
import { useAddComponentInInvitee } from "hooks/useAddComponentInInvitee"
import { EVENT } from "constants/events"
import { FormBackup } from "components/FormBackup/FormBackup"

interface Props {
  originMessages: ConversationMessagesDetailItem[]
  idConversation: string
  isEditorInput: boolean
  inviteeUser: ConversationMessagesDetail["invitee"]
  isChangeScrollMessageBottom: boolean
  updateWhenAddMessager: (message: string) => void
  onScrollMessage: () => void
  isDeleted?: boolean
  notYetCommented: boolean
  disableButtonEditor?: boolean
  projectId: string
}
const TabCoversationDetail = (props: Props) => {
  const {
    originMessages,
    isEditorInput,
    idConversation,
    inviteeUser,
    isChangeScrollMessageBottom,
    onScrollMessage,
    updateWhenAddMessager,
    isDeleted,
    notYetCommented,
    disableButtonEditor,
    projectId,
  } = props
  const userInfo = useAppSelector((state) => state.userInfo)
  const openWarningModal = useBoolean(false)

  const {
    dataEditor,
    isLoading,
    keyInputFile,
    ref,
    listFileAdd,
    setDataEditor,
    fileSelectedHandler,
    handleChangeEditor,
    handleDeleteFileNoDownload,
    setListFileAdd,
    handleChangeScroll,
    clearStorageWhenSubmit,
  } = useAdditionalAndExtraInfoProjectBuild(
    "tab_conversation_detail",
    idConversation
  )

  const {
    syncNewVersionComponentInvitee,
    isLoadingBackupInvitee,
    messageBackups,
    currentMessageBackup,
    errorMessageBackup,
  } = useAddComponentInInvitee()

  const { workerUploadFile } = useUploadFileWorkerController()

  useEffect(() => {
    if (!isChangeScrollMessageBottom) {
      return
    }
    handleChangeScroll()
  }, [JSON.stringify(originMessages), isChangeScrollMessageBottom])

  const onWarningSubmit = (dataEditor: string) => {
    console.log("onWarningSubmit -> dataEditor", notYetCommented)
    if (
      !notYetCommented ||
      !inviteeUser.email ||
      inviteeUser.email === userInfo.email ||
      inviteeUser.is_sent
    ) {
      onSubmitButton(dataEditor)
      return
    }
    openWarningModal.setValue(true)
    setDataEditor(dataEditor)
  }

  const onSubmitButton = async (dataEditor: string) => {
    if (!idConversation || !workerUploadFile) {
      toast(
        <LabelNotificationPage
          messenger="The workerUploadFile is not working!"
          type="error"
        />
      )
      return
    }

    if (!inviteeUser.is_sent && openWarningModal.value) {
      const dataInvitees = await getProjectBuildInviteeMiddleware(
        inviteeUser.project_build_id
      )
      const inviteeByConversation = dataInvitees.find(
        (el) => el.conversation_id === idConversation
      )
      if (inviteeByConversation) {
        const emailSend = inviteeByConversation.publicKeyCCs?.length
          ? inviteeByConversation.publicKeyCCs
              .filter((item) => !item.is_sent)
              .map((item) => item.email)
          : []
        if (!inviteeByConversation.is_sent_invitee) {
          emailSend.push(inviteeByConversation.email)
        }
        if (emailSend.length) {
          umamiTracking(EVENT.INVITEE.SEND_INVITATION)
          const generateDataKeyPairUsers = await syncNewVersionComponentInvitee(
            inviteeByConversation.project_build_components,
            projectId,
            inviteeByConversation,
            emailSend,
            true
          )
          configureStore.dispatch(startLoading())
          setTimeout(() => {
            postProjectBuildSendInviteeMiddleware(
              inviteeByConversation.id,
              "Send",
              (type: STATUS_RESPONSE, _messenger: string) => {
                if (type === STATUS_RESPONSE.SUCCESS) {
                  sendConversationMessage(dataEditor)
                }
              },
              emailSend,
              generateDataKeyPairUsers
            )
          }, 500)
        }
      }
      openWarningModal.setValue(false)
      return
    }
    sendConversationMessage(dataEditor)
  }

  const sendConversationMessage = async (dataEditor: string) => {
    if (!idConversation || !workerUploadFile) {
      toast(
        <LabelNotificationPage
          messenger="The workerUploadFile is not working!"
          type="error"
        />
      )
      return
    }
    isLoading.setValue(true)
    let newDataEditor = dataEditor
    listFileAdd
      .filter((item) => item.type === "inline")
      .forEach((item) => {
        newDataEditor = newDataEditor.replace(item.url || "", item.id)
      })

    configureStore.dispatch(startLoading())

    postConversationSendMessageMiddleware(
      workerUploadFile,
      idConversation,
      {
        content: isEmpty(newDataEditor) ? dataEditorDefault : newDataEditor,
        files: listFileAdd.map((file) => {
          return {
            file: file.file,
            file_name: file.name,
            key: file.id ?? uuid(),
            type: file.type,
            file_type: file.file_type,
          }
        }),
      },
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          setListFileAdd([])
          setDataEditor("")
          clearStorageWhenSubmit()
          updateWhenAddMessager(dataEditor)
        } else {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
        }
      }
    )
  }

  const onDisableButtonSubmit = () => {
    let value = true
    if (dataEditor !== dataEditorDefault || listFileAdd.length) {
      value = false
    }
    if (!isUndefined(disableButtonEditor) && disableButtonEditor) {
      return true
    }
    return value
  }
  const handleScrollMessage = () => {
    const scrollTop = ref.current?.scrollTop
    if (Number(scrollTop) < 1) {
      onScrollMessage()
      ref.current?.scrollTo(0, 1)
    }
  }
  const onchangeEditor = async (value: string) => {
    await handleChangeEditor(value)
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <div
        className="flex flex-col flex-1 h-full overflow-auto"
        onScroll={handleScrollMessage}
        ref={ref}
      >
        <div className="pr-0 md:pr-6 h-full">
          {originMessages.length
            ? originMessages.map((messages, index) => (
                <MessagesConversationItemCard
                  idUserInfo={userInfo.id}
                  idInviteeUser={inviteeUser.user_id}
                  messages={messages}
                  key={index}
                  styleRoot={{
                    marginBottom: index === originMessages.length - 1 ? 0 : 12,
                  }}
                  isActiveConversation={isEditorInput && !isDeleted}
                  conversationId={idConversation}
                />
              ))
            : null}
        </div>
      </div>
      {!inviteeUser.is_sent ? (
        <div className="pr-0 md:pr-6 mt-3">
          <div
            className="flex items-center px-2 mb-3 last:mb-0"
            style={{
              minHeight: 38,
              background: "rgba(232, 122, 22, 0.20)",
              borderRadius: 6,
            }}
          >
            <IconMessagerDeleteComponent className="icon-warning-messager" />
            <p
              className="font-normal text-13 leading-22  pl-1"
              style={{
                color: "#E87A16",
              }}
            >
              Warning: Invitation was not sent.
            </p>
          </div>
        </div>
      ) : null}

      {!isDeleted && isEditorInput ? (
        <FormInputEditorCard
          handleChangeEditor={onchangeEditor}
          dataEditor={dataEditor}
          fileSelectedHandler={fileSelectedHandler}
          listFileAdd={listFileAdd}
          keyInputFile={keyInputFile.value}
          handleDeleteFileNoDownload={handleDeleteFileNoDownload}
          onSubmitButton={onWarningSubmit}
          onDisableButtonSubmit={onDisableButtonSubmit()}
          id={idConversation}
        />
      ) : null}
      {openWarningModal.value && (
        <ModalDelete
          onCloseModal={() => openWarningModal.setValue(false)}
          title={`You are about to send a message and invitation to the invitee, which will appear in their email inbox. Please ensure the invitee's email is accurate.`}
          content="This pop-up window will not reappear once you press Proceed."
          label={`Warning`}
          onSubmit={() => onSubmitButton(dataEditor)}
          titleButton="Proceed"
          styleTitle={{
            textAlign: "center",
            // width: 360,
            marginBottom: 10,
          }}
          contentWrapperStyles={{
            paddingTop: 10,
            paddingBottom: 36,
            textAlign: "center",
          }}
        />
      )}
      {isLoadingBackupInvitee.value && messageBackups.length ? (
        <FormBackup
          messageBackups={messageBackups}
          currentBackup={currentMessageBackup}
          errorMessageBackup={errorMessageBackup.value}
        />
      ) : null}
    </div>
  )
}
export default TabCoversationDetail
